export const LaneDeparture: React.FC = () => {
  return (
    <svg
      className="di-svg"
      fill="#000000"
      height="48"
      viewBox="0 0 48 48"
      width="48"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="presentation"
      tabIndex={-1}
    >
      <path
        d="M11.783,24.898c1.477,5.668,3.188,11.271,5.068,16.828c0.627,1.866,2.153,2.806,3.789,2.697
	c4.414-0.307,8.587-1.535,12.591-3.374c1.482-0.687,2.326-2.3,1.932-4.229c-2.035-10.082-4.678-20.086-8.033-29.984
	c-0.626-1.863-2.135-2.575-3.787-2.691c-4.305-0.3-8.536,0.445-12.591,3.374C9.406,8.49,8.455,9.813,8.818,11.743
	c0.524,2.815,1.124,5.612,1.778,8.391L11.783,24.898z M13.525,18.017c2.402-3.026,9.255-4.863,12.845-3.442
	c-0.358,1.69-0.717,3.379-1.076,5.069c-2.748,0.216-5.817,1.039-8.303,2.225C15.833,20.585,14.673,19.303,13.525,18.017
	L13.525,18.017z M19.253,38.357c0.543-0.916,1.09-1.832,1.633-2.747c2.597-0.442,5.568-1.239,8.05-2.157
	c0.928,0.521,1.854,1.043,2.78,1.564C29.103,36.881,22.451,38.664,19.253,38.357L19.253,38.357z M27.694,17.638
	c1.68,4.514,2.835,9.463,3.806,14.555c-0.837-0.274-1.678-0.553-2.521-0.826c-0.886-3.309-1.771-6.615-2.656-9.917
	C26.78,20.182,27.237,18.908,27.694,17.638L27.694,17.638z M13.866,21.344c1.034,0.871,2.065,1.745,3.095,2.616
	c0.885,3.302,1.771,6.609,2.656,9.916c-0.588,0.656-1.178,1.32-1.771,1.977C16.143,30.955,14.668,26.092,13.866,21.344z"
        tabIndex={-1}
      ></path>
      <rect x="7" y="34" width="2" height="8" tabIndex={-1}></rect>
      <rect x="7" y="20" width="2" height="8" tabIndex={-1}></rect>
      <rect x="39" y="34" width="2" height="8" tabIndex={-1}></rect>
      <rect x="39" y="20" width="2" height="8" tabIndex={-1}></rect>
      <rect x="39" y="6" width="2" height="8" tabIndex={-1}></rect>
    </svg>
  );
};

import React, { useEffect, useState } from 'react';
import { getStorage, ref, listAll, getDownloadURL } from 'firebase/storage';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { CircularProgress, Grid } from '@mui/material';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import './carDetail.css';

import { AdaptiveControl } from '../../assets/adaptiveControl';
import { AndroidCarPlay } from '../../assets/androidCarplay';
import { AppleCarPlay } from '../../assets/appleCarPlay';
import { BackupCamera } from '../../assets/backupCamera';
import { BlindSpot } from '../../assets/blindSpot';
import { Bluetooth } from '../../assets/bluetooth';
import { HeatedSeat } from '../../assets/heatedseat';
import { KeylessEntry } from '../../assets/keylessEntry';
import { LaneDeparture } from '../../assets/laneDeparture';
import { MemorySeats } from '../../assets/memorySeats';
import { Navigation } from '../../assets/navigation';
import { ParkingSensors } from '../../assets/parkingSensors';
import { PowerSeats } from '../../assets/powerSeats';
import { RainSensing } from '../../assets/rainSensing';
import { SatelliteRadio } from '../../assets/satelliteRadio';
import { SideImpact } from '../../assets/sideImpact';
import { Sunroof } from '../../assets/sunroof';
import { Xenon } from '../../assets/xenon';
import { CarProps } from '../inventory/inventory';
import { db } from '../../firebase';
import { doc, getDoc } from 'firebase/firestore';
import carFax from '../../images/carfax_logo.png';
const CarDetail: React.FC = () => {
  const storage = getStorage();
  const [urls, setUrls] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const carId = searchParams.get('carId');
  const [images, setImages] = useState([]);
  const location = useLocation();
  const [Car, setCar] = useState<CarProps>();

  useEffect(() => {
    if (carId) {
      const fetchImages = async () => {
        setLoading(true);
        const listRef = ref(storage, `images/${carId}`);
        listAll(listRef)
          .then((res) => {
            let promises = res.items.map((imageRef) =>
              getDownloadURL(imageRef)
            );
            Promise.all(promises).then((urls) => {
              setUrls(urls);
            });
          })
          .catch((error) => {
            // Uh-oh, an error occurred!
          });
      };
      fetchImages();
    }
  }, [carId, storage]);

  useEffect(() => {
    if (Car) {
      if (urls.length > 0) {
        let temp = [];
        temp.push({ original: Car.Cover, thumbnail: Car.Cover });
        urls.forEach((url) => {
          temp.push({ original: url, thumbnail: url });
        });
        setImages(temp);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    }
  }, [Car, urls]);

  useEffect(() => {
    if (location && location.state && location.state.detail) {
      setCar(location.state.detail);
      return;
    }
    if (carId) {
      const getData = async () => {
        const docRef = doc(db, 'cars', carId);
        const docSnap = await getDoc(docRef);

        setCar(docSnap.data());
      };
      getData();
    }
  }, [carId, location]);

  return (
    <React.Fragment>
      <section className="listing_detail_header carDetail-header">
        <div className="container">
          {loading ? (
            <div className="carDetail-loading-header">
              <CircularProgress color="inherit" size={50} />
            </div>
          ) : (
            <div className="listing_detail_head white-text div_zindex row">
              <div className="col-md-9 carDetail-header-tittle">
                <h2>
                  {Car.Make} {Car.Model}
                </h2>
              </div>
              <div className="col-md-3">
                <div className="price_info">
                  <p>${Car.PriceDiscount}</p>
                  <p className="old_price">${Car.PriceOrignial}</p>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="dark-overlay"></div>
      </section>
      {loading ? (
        <div className="carDetail-loading">
          <CircularProgress color="inherit" size={150} />
        </div>
      ) : (
        <React.Fragment>
          <section className="listing-detail">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="listing_images">
                    {images.length > 0 && (
                      <ImageGallery
                        items={images}
                        lazyLoad={true}
                        loading="lazy"
                      />
                    )}
                  </div>

                  <Grid container className="carDetail-container">
                    <Grid item xs={12} md={12}>
                      <div className="carDetail-CarFax">
                        <a
                          target="_blank"
                          href={`http://www.carfax.com/VehicleHistory/p/Report.cfx?partner=DVW_1&vin=${Car.VIN}`}
                          rel="noreferrer"
                        >
                          <img className="carDetail-CarFaxImg" src={carFax} />
                        </a>
                      </div>
                    </Grid>
                    {Car.ANDROIDAUTO && (
                      <Grid item xs={6} md={3}>
                        <div className="carDetail-card">
                          <div className="carDetail-card-img">
                            <AndroidCarPlay />
                          </div>
                          <div className="carDetail-centralize">
                            <p>ANDROID AUTO</p>
                          </div>
                        </div>
                      </Grid>
                    )}
                    {Car.APPLECARPLAY && (
                      <Grid item xs={6} md={3}>
                        <div className="carDetail-card">
                          <div className="carDetail-card-img">
                            <AppleCarPlay />
                          </div>
                          <div className="carDetail-card-tittle">
                            <p>APPLE CARPLAY</p>
                          </div>
                        </div>
                      </Grid>
                    )}
                    {Car.BACKUPCAMERA && (
                      <Grid item xs={6} md={3}>
                        <div className="carDetail-card">
                          <div className="carDetail-card-img">
                            <BackupCamera />
                          </div>
                          <div className="carDetail-card-tittle">
                            <p>BACKUP CAMERA</p>
                          </div>
                        </div>
                      </Grid>
                    )}
                    {Car.ADAPTIVECRUISECONTROL && (
                      <Grid item xs={6} md={3}>
                        <div className="carDetail-card">
                          <div className="carDetail-card-img">
                            <AdaptiveControl />
                          </div>
                          <div className="carDetail-card-tittle">
                            <p>ADAPTIV CRUISE CONTROL</p>
                          </div>
                        </div>
                      </Grid>
                    )}
                    {Car.BLUETOOTH && (
                      <Grid item xs={6} md={3}>
                        <div className="carDetail-card">
                          <div className="carDetail-card-img">
                            <Bluetooth />
                          </div>
                          <div className="carDetail-card-tittle">
                            <p>BLUETOOTH</p>
                          </div>
                        </div>
                      </Grid>
                    )}
                    {Car.HEATEDSEATS && (
                      <Grid item xs={6} md={3}>
                        <div className="carDetail-card">
                          <div className="carDetail-card-img">
                            <HeatedSeat />
                          </div>
                          <div className="carDetail-card-tittle">
                            <p>HEATED SEATS</p>
                          </div>
                        </div>
                      </Grid>
                    )}
                    {Car.KEYLESSENTRY && (
                      <Grid item xs={6} md={3}>
                        <div className="carDetail-card">
                          <div className="carDetail-card-img">
                            <KeylessEntry />
                          </div>
                          <div className="carDetail-card-tittle">
                            <p>KEYLESS ENTRY</p>
                          </div>
                        </div>
                      </Grid>
                    )}
                    {Car.LANEDEPARTUREWARNING && (
                      <Grid item xs={6} md={3}>
                        <div className="carDetail-card">
                          <div className="carDetail-card-img">
                            <LaneDeparture />
                          </div>
                          <div className="carDetail-card-tittle">
                            <p>LANEDE PARTURE WARNING</p>
                          </div>
                        </div>
                      </Grid>
                    )}
                    {Car.MEMORYSEATS && (
                      <Grid item xs={6} md={3}>
                        <div className="carDetail-card">
                          <div className="carDetail-card-img">
                            <MemorySeats />
                          </div>
                          <div className="carDetail-card-tittle">
                            <p>MEMORY SEATS</p>
                          </div>
                        </div>
                      </Grid>
                    )}
                    {Car.NAVIGATIONSYSTEM && (
                      <Grid item xs={6} md={3}>
                        <div className="carDetail-card">
                          <div className="carDetail-card-img">
                            <Navigation />
                          </div>
                          <div className="carDetail-card-tittle">
                            <p>NAVIGATION SYSTEM</p>
                          </div>
                        </div>
                      </Grid>
                    )}
                    {Car.PARKINGSENSORSASSIST && (
                      <Grid item xs={6} md={3}>
                        <div className="carDetail-card">
                          <div className="carDetail-card-img">
                            <ParkingSensors />
                          </div>
                          <div className="carDetail-card-tittle">
                            <p>PARKING SENSORS ASSIST</p>
                          </div>
                        </div>
                      </Grid>
                    )}
                    {Car.POWERSEATS && (
                      <Grid item xs={6} md={3}>
                        <div className="carDetail-card">
                          <div className="carDetail-card-img">
                            <PowerSeats />
                          </div>
                          <div className="carDetail-card-tittle">
                            <p>POWER SEATS</p>
                          </div>
                        </div>
                      </Grid>
                    )}
                    {Car.RAINSENSINGWIPERS && (
                      <Grid item xs={6} md={3}>
                        <div className="carDetail-card">
                          <div className="carDetail-card-img">
                            <RainSensing />
                          </div>
                          <div className="carDetail-card-tittle">
                            <p>RAIN SENSING WIPERS</p>
                          </div>
                        </div>
                      </Grid>
                    )}
                    {Car.SATELLITERADIOREADY && (
                      <Grid item xs={6} md={3}>
                        <div className="carDetail-card">
                          <div className="carDetail-card-img">
                            <SatelliteRadio />
                          </div>
                          <div className="carDetail-card-tittle">
                            <p>SATELLITE RADIO READY</p>
                          </div>
                        </div>
                      </Grid>
                    )}
                    {Car.SIDEIMPACTAIRBAGS && (
                      <Grid item xs={6} md={3}>
                        <div className="carDetail-card">
                          <div className="carDetail-card-img">
                            <SideImpact />
                          </div>
                          <div className="carDetail-card-tittle">
                            <p>SIDEIMPACT AIRBAGSF</p>
                          </div>
                        </div>
                      </Grid>
                    )}
                    {Car.SUNROOFMOONROOF && (
                      <Grid item xs={6} md={3}>
                        <div className="carDetail-card">
                          <div className="carDetail-card-img">
                            <Sunroof />
                          </div>
                          <div className="carDetail-card-tittle">
                            <p>SUNROOF MOONROOF</p>
                          </div>
                        </div>
                      </Grid>
                    )}
                    {Car.XENONHEADLIGHTS && (
                      <Grid item xs={6} md={3}>
                        <div className="carDetail-card">
                          <div className="carDetail-card-img">
                            <Xenon />
                          </div>
                          <div className="carDetail-card-tittle">
                            <p>XENON HEAD LIGHTS</p>
                          </div>
                        </div>
                      </Grid>
                    )}
                    {Car.BLINDSPOTMONITOR && (
                      <Grid item xs={6} md={3}>
                        <div className="carDetail-card">
                          <div className="carDetail-card-img">
                            <BlindSpot />
                          </div>
                          <div className="carDetail-card-tittle">
                            <p>BLINDSPOT MONITOR</p>
                          </div>
                        </div>
                      </Grid>
                    )}
                  </Grid>
                  <div className="listing_more_info">
                    <div className="listing_detail_wrap">
                      <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                          <a
                            className="nav-link active"
                            id="Technical-tab"
                            data-bs-toggle="tab"
                            href="#Technical"
                            role="tab"
                            aria-controls="Technical"
                            aria-selected="true"
                          >
                            Technical Specification
                          </a>
                        </li>
                        <li className="nav-item" role="presentation">
                          <a
                            className="nav-link"
                            id="home-tab"
                            data-bs-toggle="tab"
                            href="#home"
                            role="tab"
                            aria-controls="home"
                            aria-selected="true"
                          >
                            Vehicle Overview{' '}
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content" id="myTabContent">
                        <div
                          role="tabpanel"
                          className="tab-pane"
                          id="home"
                          aria-labelledby="home-tab"
                        >
                          <p>{Car.Description}</p>
                        </div>

                        <div
                          role="tabpanel"
                          className="tab-pane active"
                          id="Technical"
                          aria-labelledby="Technical-tab"
                        >
                          <div className="table-responsive">
                            <table>
                              <tbody>
                                <tr>
                                  <td className="carDetail-column">
                                    <p>
                                      <b>Make: </b>
                                      {Car.Make}
                                    </p>
                                  </td>
                                  <td>
                                    <p>
                                      <b>Drivetrain: </b>
                                      {Car.EngineType}
                                    </p>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="carDetail-column">
                                    <p>
                                      <b>Model: </b>
                                      {Car.Model}
                                    </p>
                                  </td>
                                  <td>
                                    <p>
                                      <b>Engine Description: </b>
                                      {Car.EngineDescription}
                                    </p>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="carDetail-column">
                                    <p>
                                      <b>Model Year: </b>
                                      {Car.ModelYear}
                                    </p>
                                  </td>
                                  <td>
                                    <p>
                                      <b>Mileage: </b>
                                      {Car.Mileage}
                                    </p>
                                  </td>
                                </tr>

                                <tr>
                                  <td className="carDetail-column">
                                    <p>
                                      <b>Exterior: </b>
                                      {Car.Exterior}
                                    </p>
                                  </td>
                                  <td>
                                    <p>
                                      <b>Fuel Type: </b>
                                      {Car.FuelType}
                                    </p>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="carDetail-column">
                                    <p>
                                      <b>Interior: </b>
                                      {Car.Interior}
                                    </p>
                                  </td>
                                  <td>
                                    <p>
                                      <b>Seating Capacity: </b>
                                      {Car.SeatingCapacity}
                                    </p>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="carDetail-column">
                                    <p>
                                      <b>VIN: </b>
                                      {Car.VIN}
                                    </p>
                                  </td>
                                  <td>
                                    <p>
                                      <b>Transmission Type: </b>
                                      {Car.TransmissionType}
                                    </p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="carDetail-buy">
            {!Car.Sold && (
              <Link
                to={{
                  pathname: '/contactUs',
                  search: `?subject=Buy`,
                }}
                state={{ detail: Car }}
                className="btn carDetail-buy-button"
              >
                Get Your Price Now!
                <span className="angle_arrow">
                  <i className="fa fa-angle-right" aria-hidden="true"></i>
                </span>
              </Link>
            )}
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
export default CarDetail;

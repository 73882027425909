export const Navigation: React.FC = () => {
  return (
    <svg
      className="di-svg"
      fill="#000000"
      height="48"
      viewBox="0 0 48 48"
      width="48"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="presentation"
      tabIndex={-1}
    >
      <g tabIndex={-1}>
        <path
          d="M24.484,37.58c-0.038-0.005-0.115-0.009-0.229-0.009H24.1v0.445h0.175c0.125,0,0.21-0.007,0.253-0.024
			c0.041-0.016,0.075-0.042,0.099-0.076c0.023-0.035,0.036-0.076,0.036-0.123c0-0.058-0.017-0.104-0.051-0.141
			C24.579,37.614,24.537,37.592,24.484,37.58z"
          tabIndex={-1}
        ></path>
        <path
          d="M40.415,7.5h-8.792H7.586C5.987,7.5,4,9.022,4,10.882V37.12c0,1.859,1.987,3.38,3.586,3.38h5.519h27.311
			c1.599,0,3.585-1.521,3.585-3.38V10.882C44,9.022,42.014,7.5,40.415,7.5z M19.929,9.338h8.608v1.038h-8.608V9.338z M38.809,35.599
			H9.19V12.089h29.618V35.599z"
          tabIndex={-1}
        ></path>
      </g>
      <path
        d="M26,26.502v-2.5h-4v3h-2v-4c0-0.552,0.447-1,1-1h5v-2.5l3.499,3.5 M33.708,23.292l-8.998-9H24.7
		c-0.39-0.39-1.021-0.39-1.41,0l-8.998,9c-0.389,0.39-0.389,1.03,0,1.42l8.998,8.999c0.39,0.38,1.021,0.39,1.42,0l8.998-8.999
		C34.098,24.322,34.098,23.682,33.708,23.292z"
        tabIndex={-1}
      ></path>
    </svg>
  );
};

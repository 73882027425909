export const RainSensing: React.FC = () => {
  return (
    <svg
      className="di-svg"
      fill="#000000"
      height="48"
      viewBox="0 0 48 48"
      width="48"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="presentation"
      tabIndex={-1}
    >
      <path
        d="M23.998,18.151c-9.227,0-15.108,1.758-15.108,1.758
	c-0.485,0.142-0.853,0.516-0.965,0.972L3.04,40.511c-0.187,0.706,0.303,1.432,1.093,1.596c0.263,0.066,0.535,0.047,0.789-0.023
	c0,0,8.753-2.624,19.077-2.624c10.329,0,19.073,2.624,19.073,2.624c0.771,0.231,1.599-0.146,1.853-0.85
	c0.088-0.232,0.1-0.486,0.037-0.723L40.07,20.881c-0.113-0.457-0.482-0.83-0.967-0.972C39.104,19.909,33.227,18.151,23.998,18.151
	L23.998,18.151z M23.998,20.833c7.07,0,11.756,0.997,13.41,1.41l4.148,16.677c-1.731-0.405-4.326-0.961-7.485-1.406l-1.672-5.085
	l6.416,2.431c0.3,0.117,0.634-0.019,0.757-0.281c0.121-0.276-0.021-0.581-0.312-0.68l-15.228-5.775
	c-0.091-0.035-0.19-0.056-0.29-0.047c-0.315,0.023-0.551,0.28-0.524,0.573c0.02,0.199,0.162,0.375,0.37,0.445l7.081,2.681
	c-0.079,0.164-0.095,0.351-0.031,0.53l1.63,4.974c-2.516-0.284-5.312-0.492-8.269-0.492c-1.192,0-2.359,0.044-3.492,0.094
	l-1.565-4.756l6.417,2.43c0.299,0.117,0.635-0.021,0.758-0.281c0.118-0.276-0.019-0.586-0.311-0.697l-15.232-5.758
	c-0.087-0.044-0.187-0.058-0.287-0.047c-0.315,0.023-0.553,0.281-0.528,0.565c0.017,0.207,0.164,0.383,0.374,0.453l7.077,2.682
	c-0.078,0.163-0.091,0.35-0.031,0.515l1.638,5.009c-5.358,0.399-9.808,1.315-12.374,1.925l4.151-16.677
	C12.244,21.831,16.928,20.833,23.998,20.833z"
        tabIndex={-1}
      ></path>
      <path
        d="M35,11.474c0-2.456-2.537-5.339-2.646-5.462c-0.1-0.11-0.229-0.163-0.354-0.163s-0.255,0.053-0.354,0.163
		C31.537,6.135,29,9.018,29,11.474c0,1.859,1.347,3.375,3,3.375S35,13.333,35,11.474z"
        tabIndex={-1}
      ></path>
      <path
        d="M28,20.651c0-3.275-3.383-7.119-3.527-7.283c-0.133-0.146-0.305-0.217-0.473-0.217s-0.34,0.07-0.473,0.217
		C23.383,13.533,20,17.376,20,20.651c0,2.479,1.796,4.5,4,4.5S28,23.13,28,20.651z"
        tabIndex={-1}
      ></path>
      <path
        d="M18,14.276c0-2.456-2.537-5.339-2.646-5.462c-0.1-0.11-0.229-0.163-0.354-0.163s-0.255,0.053-0.354,0.163
		C14.537,8.938,12,11.82,12,14.276c0,1.859,1.347,3.375,3,3.375S18,16.136,18,14.276z"
        tabIndex={-1}
      ></path>
    </svg>
  );
};

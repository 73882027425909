export const BlindSpot: React.FC = () => {
  return (
    <svg
      className="di-svg"
      fill="#000000"
      height="48"
      viewBox="0 0 48 48"
      width="48"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="presentation"
      tabIndex={-1}
    >
      <path
        d="M28.449,16.5c-0.031,4.333,0.126,8.665,0.42,12.994c0.097,1.453,1.054,2.416,2.304,2.65c3.378,0.628,6.76,0.548,10.139,0
        c1.252-0.205,2.21-1.195,2.302-2.65c0.501-7.592,0.53-15.246,0-22.959c-0.095-1.451-1.084-2.247-2.302-2.647
        c-3.174-1.039-6.503-1.316-10.139,0c-1.207,0.436-2.188,1.199-2.304,2.647c-0.172,2.112-0.284,4.225-0.352,6.336L28.449,16.5z
         M31.142,11.918c2.415-1.704,7.933-1.704,10.345,0c-0.609,1.138-1.219,2.275-1.829,3.413c-2.106-0.37-4.58-0.37-6.687,0
        C32.358,14.193,31.747,13.056,31.142,11.918L31.142,11.918z M31.353,27.546c0.592-0.55,1.188-1.099,1.779-1.649
        c2.04,0.178,4.435,0.178,6.483,0c0.591,0.551,1.183,1.1,1.773,1.649C39.049,28.378,33.692,28.378,31.353,27.546L31.353,27.546z
         M41.864,14.361c0.353,3.544,0.226,7.301-0.071,11.127c-0.572-0.358-1.148-0.718-1.727-1.073c0-2.534,0-5.068,0-7.596
        C40.666,16.001,41.266,15.18,41.864,14.361L41.864,14.361z M30.729,14.361c0.601,0.819,1.2,1.64,1.797,2.458
        c0,2.528,0,5.062,0,7.596c-0.573,0.355-1.149,0.715-1.728,1.073C30.506,21.662,30.377,17.905,30.729,14.361z"
        tabIndex={-1}
      ></path>
      <g tabIndex={-1}>
        <path
          fill="none"
          d="M17.832,43.749c0,0.418,0,0.834,0,1.251h2.553c-0.033-1.491-0.097-2.969-0.24-4.414
            C19.373,41.64,18.602,42.696,17.832,43.749z"
          tabIndex={-1}
        ></path>
        <path
          fill="none"
          d="M17.303,41.837c0.787-1.465,1.571-2.928,2.354-4.391c-3.101-2.19-10.198-2.19-13.302,0
            c0.783,1.463,1.567,2.926,2.352,4.391C11.416,41.358,14.594,41.358,17.303,41.837z"
          tabIndex={-1}
        ></path>
        <path
          fill="none"
          d="M5.829,40.586C5.684,42.031,5.62,43.509,5.587,45h2.552c0-0.417,0-0.833,0-1.251
            C7.369,42.696,6.597,41.64,5.829,40.586z"
          tabIndex={-1}
        ></path>
        <path
          d="M6.284,39.456c0.6,0.817,1.199,1.639,1.799,2.458c0,0.325,0,0.649,0,0.973h7.542c0-0.323,0-0.647,0-0.973
            c0.599-0.819,1.2-1.641,1.799-2.458c0.112,1.121,0.163,2.27,0.187,3.431h1.945c-0.003-3.738-0.125-7.49-0.382-11.26
            c-0.098-1.448-1.086-2.248-2.303-2.647c-3.176-1.038-6.507-1.316-10.145,0c-1.208,0.436-2.188,1.201-2.303,2.647
            c-0.169,2.111-0.284,4.226-0.351,6.336l-0.07,3.632c-0.003,0.432,0.003,0.862,0.004,1.292h2.091
            C6.123,41.726,6.172,40.577,6.284,39.456z M17.043,37.012c-0.608,1.138-1.22,2.275-1.831,3.414c-2.109-0.372-4.581-0.372-6.689,0
            c-0.609-1.139-1.222-2.276-1.831-3.414C9.109,35.311,14.629,35.311,17.043,37.012z"
          tabIndex={-1}
        ></path>
      </g>
      <path
        d="M25.668,20.612c-0.004,0.198-0.088,0.36-0.236,0.482c-0.293,0.228-0.782,0.267-1.001,0.256
        c-1.394-0.078-2.65-0.75-3.353-1.802c-0.375-0.559-0.572-1.195-0.572-1.842c0-0.068,0.002-0.135,0.005-0.203
        c0.014-0.208,0.152-0.595,0.419-0.806c0.159-0.125,0.335-0.175,0.546-0.154c0.115,0.012,0.206,0.058,0.281,0.142
        c0.17,0.189,0.185,0.479,0.185,0.563c0,0.047-0.004,0.091-0.011,0.132c-0.017,0.111-0.025,0.22-0.025,0.328
        c0,0.435,0.127,0.844,0.381,1.222c0.544,0.809,1.589,1.279,2.661,1.196c0.148-0.011,0.389,0.083,0.549,0.21
        c0.108,0.088,0.172,0.188,0.172,0.272V20.612z"
        tabIndex={-1}
      ></path>
      <path
        d="M25.625,23.71c0,0.019-0.006,0.035-0.007,0.055l-0.002,0.031c-0.017,0.174-0.118,0.354-0.282,0.481
        c-0.146,0.115-0.323,0.187-0.504,0.191h-0.007h-0.044c-0.064-0.004-0.202-0.011-0.474-0.031c-2.599-0.187-4.932-1.466-6.24-3.417
        c-0.712-1.067-1.091-2.28-1.091-3.509c0-0.112,0.004-0.225,0.008-0.337l0.023-0.398v-0.009c0.023-0.098,0.119-0.229,0.238-0.32
        c0.168-0.136,0.388-0.21,0.604-0.204h0.045c0.192,0.007,0.357,0.07,0.453,0.181c0.06,0.062,0.086,0.139,0.086,0.224
        c0,0.027-0.003,0.054-0.008,0.08l-0.034,0.415c-0.011,0.122-0.015,0.247-0.015,0.371c0,1.011,0.311,2.009,0.898,2.886
        c1.123,1.677,3.123,2.733,5.352,2.833l0.425,0.017c0.188-0.012,0.316,0.021,0.422,0.107C25.568,23.442,25.625,23.568,25.625,23.71z"
        tabIndex={-1}
      ></path>
      <path
        d="M25.574,26.865c0,0.019,0,0.034,0,0.052c-0.015,0.183-0.102,0.341-0.262,0.468c-0.146,0.112-0.328,0.18-0.508,0.182h-0.011
        l-0.013-0.001c-0.033,0-0.151-0.008-0.485-0.031c-3.848-0.263-7.305-2.148-9.245-5.045c-1.053-1.569-1.606-3.356-1.606-5.17
        c0-0.165,0.004-0.331,0.013-0.495l0.02-0.372c0.019-0.111,0.099-0.229,0.212-0.321c0.16-0.125,0.375-0.192,0.591-0.188
        c0.228,0.006,0.417,0.074,0.526,0.188c0.06,0.065,0.091,0.135,0.091,0.209c0,0.015,0,0.032-0.002,0.049l-0.029,0.353
        c-0.012,0.193-0.019,0.389-0.019,0.582c0,1.594,0.487,3.167,1.413,4.548c1.726,2.576,4.804,4.242,8.227,4.449l0.46,0.028
        c0.192-0.006,0.355,0.047,0.468,0.151C25.521,26.594,25.574,26.725,25.574,26.865z"
        tabIndex={-1}
      ></path>
    </svg>
  );
};

import React from 'react';
import xiaohongshu from '../../images/xiaohongshu.png';
import './footer.css';

const Footer: React.FC = () => {
  return (
    <React.Fragment>
      <footer>
        <div className="footer-top footer-size">
          <div className="container">
            <div className="row">
              <div className="col-md-3 col-sm-6">
                <h6>Call:</h6>
                <ul>
                  <li>
                    <a target="_blank" href="tel:617-481-8998" rel="noreferrer">
                      +1 617-481-8998{' '}
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-md-4 col-sm-6">
                <h6>Email:</h6>
                <ul>
                  <li>
                    <a
                      target="_blank"
                      href="mailto:srautogroup.ma@gmail.com"
                      rel="noreferrer"
                    >
                      srautogroup.ma@gmail.com
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-md-5 col-sm-6">
                <h6>Adress:</h6>
                <ul>
                  <li>
                    <a
                      target="_blank"
                      href="http://maps.google.com/?q=SR Auto Group Massachusetts, Quincy, W Howard St,02169"
                      rel="noreferrer"
                    >
                      SR Auto Group Massachusetts, Quincy, W Howard St
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-md-8 ">
                <p className="copy-right">
                  Copyright &copy; 2022 SR Auto Group. All Rights Reserved
                </p>
              </div>
              <div className="col-md-4 text-right">
                <div className="footer_widget">
                  <p>Connect with Us:</p>
                  <ul>
                    <li>
                      <a
                        href="https://www.instagram.com/invites/contact/?i=q6rz9g819jcy&utm_content=md7xrz9"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i
                          className="fa fa-brands fa-instagram"
                          aria-hidden="true"
                        ></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.xiaohongshu.com/user/profile/613a4a9b00000000020181a1?xhsshare=CopyLink&appuid=613a4a9b00000000020181a1&apptime=1673835965"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          className="navigation-xiaohongshu"
                          src={xiaohongshu}
                          alt="xiaohongshu logo"
                        ></img>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div id="back-top" className="back-top">
        {' '}
        <a href="#top">
          <i className="fa fa-angle-up" aria-hidden="true"></i>{' '}
        </a>{' '}
      </div>
    </React.Fragment>
  );
};
export default Footer;

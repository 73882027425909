export const BackupCamera: React.FC = () => {
  return (
    <svg
      className="di-svg"
      fill="#000000"
      height="48"
      viewBox="0 0 48 48"
      width="48"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="presentation"
      tabIndex={-1}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.005,19.251c-0.039,5.856,0.165,11.714,0.54,17.569
            c0.125,1.964,1.353,3.267,2.961,3.583c4.346,0.848,8.693,0.742,13.038,0c1.612-0.277,2.84-1.617,2.961-3.583
            c0.642-10.267,0.678-20.613,0-31.042c-0.126-1.963-1.395-3.041-2.961-3.581c-4.082-1.404-8.362-1.781-13.038,0
            c-1.552,0.59-2.812,1.62-2.961,3.581c-0.217,2.854-0.364,5.71-0.451,8.564L14.005,19.251z M17.465,13.054
            c3.103-2.302,10.203-2.302,13.303,0c-0.784,1.54-1.567,3.079-2.353,4.619c-2.71-0.503-5.887-0.503-8.596,0
            C19.034,16.133,18.25,14.594,17.465,13.054L17.465,13.054z M17.741,34.191c0.762-0.745,1.522-1.49,2.283-2.234
            c2.627,0.242,5.708,0.242,8.335,0c0.761,0.744,1.523,1.489,2.284,2.234C27.635,35.309,20.749,35.309,17.741,34.191L17.741,34.191z
             M31.256,16.359c0.455,4.793,0.287,9.875-0.09,15.044c-0.74-0.483-1.48-0.969-2.222-1.452c0-3.423,0-6.847,0-10.267
            C29.714,18.577,30.484,17.466,31.256,16.359L31.256,16.359z M16.939,16.359c0.77,1.107,1.54,2.218,2.312,3.326
            c0,3.42,0,6.844,0,10.267c-0.741,0.483-1.482,0.969-2.224,1.452C16.651,26.233,16.485,21.152,16.939,16.359z"
        tabIndex={-1}
      ></path>
      <rect
        x="27.5"
        y="42.5"
        transform="matrix(0.9397 -0.3419 0.3419 0.9397 -13.3562 12.2248)"
        fill-rule="evenodd"
        clip-rule="evenodd"
        width="2"
        height="6"
        tabIndex={-1}
      ></rect>
      <rect
        x="19.499"
        y="42.5"
        transform="matrix(0.9397 0.3419 -0.3419 0.9397 16.2488 -4.1861)"
        fill-rule="evenodd"
        clip-rule="evenodd"
        width="2"
        height="6"
        tabIndex={-1}
      ></rect>
    </svg>
  );
};

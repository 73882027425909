import React from 'react';
import oilChange from '../../images/oilChange.png';
import tire from '../../images/tire.png';
import engineCheck from '../../images/engineCheck.png';
import brakeService from '../../images/brakeService.png';
import autoBody from '../../images/autoBody.png';
import serviceSpecials from '../../images/serviceSpecials.png';
import './services.css';
import { Link } from 'react-router-dom';

const Services: React.FC = () => {
  return (
    <React.Fragment>
      <section className="about_us section-padding">
        <div className="container">
          <div className="section-header text-center">
            <h2>
              Welcome <span>to the Service</span>
            </h2>
            <p>We strive to provide the best service possible </p>
            <Link
              to={{
                pathname: '/contactUs',
                search: `?subject=service`,
              }}
              className="btn"
            >
              SCHEDULE SERVICE{' '}
              <span className="angle_arrow">
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </span>
            </Link>{' '}
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-4">
              <div className="about_content row">
                <div className="col-md-10 col-sm-10 services-card">
                  <Link
                    to={{
                      pathname: '/contactUs',
                      search: `?subject=service`,
                    }}
                  >
                    <div className="about_img">
                      <img src={oilChange} alt="image" />{' '}
                    </div>
                    <h3>OIL CHANGE</h3>
                  </Link>{' '}
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="about_content row">
                <div className="col-md-10 col-sm-10 services-card">
                  <Link
                    to={{
                      pathname: '/contactUs',
                      search: `?subject=service`,
                    }}
                  >
                    <div className="about_img">
                      {' '}
                      <img src={engineCheck} alt="image" />{' '}
                    </div>
                    <h3>ENGINE CHECK</h3>
                  </Link>{' '}
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="about_content row">
                <div className="col-md-10 col-sm-10 services-card">
                  <Link
                    to={{
                      pathname: '/contactUs',
                      search: `?subject=service`,
                    }}
                  >
                    <div className="about_img">
                      <img src={tire} alt="image" />{' '}
                    </div>
                    <h3>TIRE & RIM</h3>
                  </Link>{' '}
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="about_content row">
                <div className="col-md-10 col-sm-10 services-card">
                  <Link
                    to={{
                      pathname: '/contactUs',
                      search: `?subject=service`,
                    }}
                  >
                    <div className="about_img">
                      {' '}
                      <img src={brakeService} alt="image" />{' '}
                    </div>
                    <h3>BRAKE SERVICE</h3>
                  </Link>{' '}
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="about_content row">
                <div className="col-md-10 col-sm-10 services-card">
                  <Link
                    to={{
                      pathname: '/contactUs',
                      search: `?subject=service`,
                    }}
                  >
                    <div className="about_img">
                      {' '}
                      <img src={autoBody} alt="image" />{' '}
                    </div>
                    <h3>AUTO BODY</h3>
                  </Link>{' '}
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="about_content row">
                <div className="col-md-10 col-sm-10 services-card">
                  <Link
                    to={{
                      pathname: '/contactUs',
                      search: `?subject=service`,
                    }}
                  >
                    <div className="about_img">
                      {' '}
                      <img src={serviceSpecials} alt="image" />{' '}
                    </div>
                    <h3>SERVICE SPECIALS</h3>
                  </Link>{' '}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Services;

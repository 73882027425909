import './filter.css';
import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useEffect, useState } from 'react';
import { collection, getDocs, query } from 'firebase/firestore';
import { db } from '../../firebase';
import MenuItem from '@mui/material/MenuItem';
import { Menu, TextField } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
type FilterProps = {
  handleFilterApply: (conditions) => void;
};

export const Filter: React.FC<FilterProps> = ({ handleFilterApply }) => {
  const [carData, setCarData] = useState([]);
  const [makeData, setMakeData] = useState([]);
  const [modelData, setModelData] = useState([]);

  const defaultCondition = {
    make: '',
    model: '',
    maxPrice: '',
    maxMileage: '',
  };
  const [conditions, setConditions] = useState(defaultCondition);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (name === 'maxPrice' || name === 'maxMileage') {
      if (isNaN(+value)) {
        return;
      }
    }
    setConditions((values) => ({ ...values, [name]: value }));
  };

  const handleReset = () => {
    setConditions(defaultCondition);
  };

  const handleConfirm = () => {
    handleFilterApply(conditions);
    handleClose();
  };

  useEffect(() => {
    const query_ = query(collection(db, 'filter'));
    const subscribe = async () => {
      const tempData = [];
      const documents = await getDocs(query_);
      documents.forEach((document) => {
        tempData.push({
          make: document.id,
          model: document.data(),
        });
      });
      setCarData(tempData);
    };
    subscribe();
  }, []);
  useEffect(() => {
    if (carData.length > 0) {
      const make = carData.map((car) => car.make);
      setMakeData(make);
    }
  }, [carData]);

  useEffect(() => {
    if (conditions.make) {
      const model = [];
      carData.forEach((car) => {
        if (car.make === conditions.make) {
          model.push(car.model);
        }
      });

      setModelData(Object.keys(model[0]));
    } else {
      setModelData([]);
    }
  }, [carData, conditions.make]);

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant="outlined"
        startIcon={<FilterListIcon />}
      >
        Filter Inventory
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="demo-simple-select-helper-label">Make</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              label="Make"
              id="demo-simple-select-helper"
              className="filter-input"
              value={conditions.make}
              name="make"
              onChange={(event) => handleInputChange(event)}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {makeData.map((make) => (
                <MenuItem value={make}>{make}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="demo-simple-select-helper-label">Model</InputLabel>
            <Select
              id="demo-simple-select-helper"
              labelId="demo-simple-select-helper-label"
              label="Model"
              className="filter-input"
              value={conditions.model}
              name="model"
              onChange={(event) => handleInputChange(event)}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {modelData.length > 0 &&
                modelData.map((model) => (
                  <MenuItem value={model}>{model}</MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>

        <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <TextField
              className="filter-input"
              label="Max Price:"
              placeholder="50000"
              value={conditions.maxPrice}
              name="maxPrice"
              onChange={(event) => handleInputChange(event)}
              type="number"
            />
          </FormControl>
        </Box>
        <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <TextField
              className="filter-input"
              value={conditions.maxMileage}
              name="maxMileage"
              onChange={(event) => handleInputChange(event)}
              placeholder="30000"
              label="Max Mileage:"
              type="number"
            />
          </FormControl>
        </Box>
        <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Button
              variant="contained"
              className="filter-input"
              onClick={handleReset}
            >
              Reset
            </Button>
          </FormControl>
        </Box>
        <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Button
              className="filter-input"
              variant="contained"
              color="error"
              onClick={handleConfirm}
            >
              Comfirm
            </Button>
          </FormControl>
        </Box>
      </Menu>
    </div>
  );
};

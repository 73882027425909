import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Rating,
} from '@mui/material';

import { Link } from 'react-router-dom';
import './autoSPA.css';
const AutoSPA: React.FC = () => {
  return (
    <section className="compare-page inner_pages">
      <div className="container">
        <div className="compare_info">
          <div className="section-header text-center">
            <h2>
              Welcome <span>to the Auto SPA</span>
            </h2>
            <p>We strive to provide the best service possible </p>
            <Link
              to={{
                pathname: '/contactUs',
                search: `?subject=SPA`,
              }}
              className="btn"
            >
              MAKE AN APPOINTMENT{' '}
              <span className="angle_arrow">
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </span>
            </Link>
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <div className="autoSPA-box">
                <List>
                  <ListItem className="autoSPA-rating">
                    <p className="autoSPA-tittle">Platinum Package </p>
                  </ListItem>
                  <Divider />
                  <ListItem className="autoSPA-rating" divider>
                    <Rating name="read-only" value={4} max={4} readOnly />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Our premium package offers a thorough interior and exterior detailing. Beyond the aforementioned services, it includes an intensive cleaning of the engine bay and the fuel cap interior." />{' '}
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemText primary="Cars - Starting at $220" />
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemText primary="SUV - Starting at $240" />
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemText primary="Trucks - Starting at $255" />
                  </ListItem>
                </List>
              </div>
            </Grid>
            <Grid item xs={12} md={3}>
              <div className="autoSPA-box">
                <List component="nav" aria-label="mailbox folders">
                  <ListItem>
                    <ListItem className="autoSPA-rating">
                      <p className="autoSPA-tittle">Exterior Detailing</p>
                    </ListItem>
                  </ListItem>
                  <Divider />
                  <ListItem className="autoSPA-rating" divider>
                    <Rating name="read-only" value={3} max={3} readOnly />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="This package is exclusively tailored for your vehicle's exterior. It features a meticulous hand wash, thorough cleaning of all windows and mirrors, intensive care for wheels and tires, a clay treatment, and hand buffing. We finalize with a wax and tire shine to achieve a distinctive gleam and profound gloss." />
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemText primary="Cars - Starting at $125" />
                  </ListItem>

                  <Divider />
                  <ListItem>
                    <ListItemText primary="SUV - Starting at $135" />
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemText primary="Trucks - Starting at $145" />
                  </ListItem>
                </List>
              </div>
            </Grid>
            <Grid item xs={12} md={3}>
              <div className="autoSPA-box">
                <List component="nav" aria-label="mailbox folders">
                  <ListItem>
                    <ListItem className="autoSPA-rating">
                      <p className="autoSPA-tittle">Interior Detailing</p>
                    </ListItem>
                  </ListItem>
                  <Divider />
                  <ListItem className="autoSPA-rating" divider>
                    <Rating name="read-only" value={3} max={3} readOnly />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="This package emphasizes interior care, encompassing a thorough vacuuming, including the trunk, deep cleaning of floor mats, pressurized steaming of all panels, and meticulous cleaning of interior windows and door jambs" />
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemText primary="Cars - Starting at $105" />
                  </ListItem>

                  <Divider />
                  <ListItem>
                    <ListItemText primary="SUV - Starting at $125" />
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemText primary="Trucks - Starting at $135" />
                  </ListItem>
                </List>
              </div>
            </Grid>
            <Grid item xs={12} md={3}>
              <div className="autoSPA-box">
                <List component="nav" aria-label="mailbox folders">
                  <ListItem>
                    <ListItem className="autoSPA-rating">
                      <p className="autoSPA-tittle">Wash and Vac </p>
                    </ListItem>
                  </ListItem>
                  <Divider />
                  <ListItem className="autoSPA-rating" divider>
                    <Rating name="read-only" value={3} max={3} readOnly />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="This package Ideal for last-minute date nights or when time is of the essence. It comprises a hand wash, vacuum, wheel and tire cleanse, a swift interior panel wipe-down, cleaning of windows inside and out, and concludes with a spray sealant to impart that added sheen." />
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemText primary="Cars - Starting at $50" />
                  </ListItem>

                  <Divider />
                  <ListItem>
                    <ListItemText primary="SUV - Starting at $65" />
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemText primary="Trucks - Starting at $75" />
                  </ListItem>
                </List>
              </div>
            </Grid>
          </Grid>

          {/* <div className="compare_product_info">
            <div className="inventory_info_list">
              <div className="listing_heading">
                <div>The Rejuvenation Package</div>
                <div>The Exterior Package</div>
                <div>The Interior Package</div>
                <div>The Quick-To-Go Package</div>
              </div>

              <ul>
                <li className="info_heading">
                  <div className="autoSPA-rating">
                    {' '}
                    <Rating name="read-only" value={4} max={4} readOnly />
                  </div>
                  <div className="autoSPA-box">
                    <p className="autoSPA-text">
                      The package includes a hand wash, machine polish and paste
                      was on all painted surfaces of your car. We clean and
                      shampoo the entire interiro, door panels, seats,
                      dashboard, center console and carpets. The engine, door
                      jambs, trunk, wheel and the windows are all cleaned as
                      well.
                    </p>
                  </div>
                  <div>
                    <p className="autoSPA-text">Cars - Starting at $185</p>
                  </div>
                  <div>
                    <p className="autoSPA-text">SUV - Starting at $215</p>
                  </div>
                </li>
                <li>
                  <div className="autoSPA-rating">
                    {' '}
                    <Rating name="read-only" value={3} max={3} readOnly />
                  </div>
                  <div className="autoSPA-box">
                    <p className="autoSPA-text">
                      The package focuses on the outside of your vehicle. This
                      includes a hand wash, machine polish and paste was on all
                      the painted surfaces of your car. We also vacuum the
                      interior. clean the windows, wheels and door jambs as
                      well.
                    </p>
                  </div>
                  <div>
                    <p className="autoSPA-text">Cars - Starting at $105</p>
                  </div>
                  <div>
                    <p className="autoSPA-text">SUV - Starting at $120</p>
                  </div>
                </li>
                <li>
                  <div className="autoSPA-rating">
                    {' '}
                    <Rating name="read-only" value={3} max={3} readOnly />
                  </div>
                  <div className="autoSPA-box">
                    <p className="autoSPA-text">
                      The package focuses on the interior of your vehicle. We
                      clean and shampoo the entire interior of your car. We also
                      hand wash and dry the exterior, as well as the windows and
                      the wheels.
                    </p>
                  </div>
                  <div>
                    <p className="autoSPA-text">Cars - Starting at $105</p>
                  </div>
                  <div>
                    <p className="autoSPA-text">SUV - Starting at $120</p>
                  </div>
                </li>
                <li>
                  <div className="autoSPA-rating">
                    {' '}
                    <Rating name="read-only" value={2} max={2} readOnly />
                  </div>
                  <div className="autoSPA-box">
                    <p className="autoSPA-text">
                      This is perfect if you have a night out of the twon paln!
                      This includes a hand wash, vacuum. tire shine, quick wipe
                      down of the interior, windows inside and out , and a spray
                      wax.
                    </p>
                  </div>
                  <div>
                    <p className="autoSPA-text">Cars - Starting at $50</p>
                  </div>
                  <div>
                    <p className="autoSPA-text">SUV - Starting at $70</p>
                  </div>
                </li>
              </ul>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
};
export default AutoSPA;

import React from 'react';
import { Link } from 'react-router-dom';
import carFax from '../../images/carfax.png';
import './home.css';

const Home: React.FC = () => {
  return (
    <React.Fragment>
      <div className="home-backgroud">
        <section className="page-header home-header">
          <div className="container">
            <div className="page-header_wrap">
              <div className="page-heading">
                <h1 className="home-header-text">ASK ME FOR THE KEY</h1>
              </div>
            </div>
          </div>
        </section>
        <img src={carFax} className="home-image-top" alt="carFax" />
      </div>

      <section className="faq section-padding">
        <div className="container">
          <div className="section-header text-center home-section-header">
            <h2>SERVICES</h2>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="faq_category white-text home-card">
                <div className="div_zindex">
                  <Link to="/inventory">
                    <h3>Auto Dealer</h3>
                  </Link>
                </div>
                <div className="dark-overlay"></div>
              </div>
              <div className="faq_text"></div>
            </div>
            <div className="col-md-3">
              <div className="faq_category white-text home-card">
                <div className="div_zindex">
                  <Link to="/services">
                    <h3>Servie/Repair</h3>
                  </Link>
                </div>
                <div className="dark-overlay"></div>
              </div>
              <div className="faq_text"></div>
            </div>
            <div className="col-md-3">
              <div className="faq_category white-text home-card ">
                <div className="div_zindex">
                  <Link to="/wrapping">
                    <h3>Auto spa & wrap</h3>
                  </Link>
                </div>
                <div className="dark-overlay"></div>
              </div>
              <div className="faq_text"></div>
            </div>
            <div className="col-md-3">
              <div className="faq_category white-text home-card">
                <div className="div_zindex">
                  <Link to="/contactUs">
                    <h3>Nationwide delivery</h3>
                  </Link>
                </div>
                <div className="dark-overlay"></div>
              </div>
              <div className="faq_text"></div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};
export default Home;

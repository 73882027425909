import React, { useCallback, useEffect, useState } from 'react';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { storage } from '../../firebase';
import LinearProgress, {
  LinearProgressProps,
} from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Alert, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

type ImageDemoProps = {
  uuid: string;
  url: string;
  image: File;
  handleError: (error: string) => void;
  handleDelete: (item: File) => void;
  handleDownloadURL?: (url: string) => void;
  beginUpload: boolean;
};

const ImageDemo: React.FC<ImageDemoProps> = ({
  uuid,
  url,
  image,
  beginUpload,
  handleError,
  handleDelete,
  handleDownloadURL,
}) => {
  const [progress, setProgress] = useState(0);
  const [done, setDone] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [start, setStart] = useState(false);
  const [isStart, setIsStart] = useState(false);
  const LinearProgressWithLabel = (
    props: LinearProgressProps & { value: number }
  ) => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    );
  };

  const handleUpload = useCallback(() => {
    const metadata = {
      contentType: 'image/jpeg',
    };
    setIsStart(true);
    setHasError(false);
    setProgress(0);
    setStart(false);
    const storageRef = ref(storage, `images/${uuid}/${image.name}`);
    const uploadTask = uploadBytesResumable(storageRef, image, metadata);
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(percent);
      },
      (err) => {
        handleError(`Upload ${image.name} error: ${err}`);
        setHasError(true);
      },
      async () => {
        await getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          handleDownloadURL &&  handleDownloadURL(url);
          setDone(true);
        });
      }
    );
  }, [handleDownloadURL, handleError, image, uuid]);

  useEffect(() => {
    if (!start || isStart) return;
    handleUpload();
  }, [handleUpload, isStart, start]);

  useEffect(() => {
    setStart(beginUpload);
  }, [beginUpload]);

  return (
    <div>
      <img src={url} alt="car images" />
      {!isStart && (
        <Button
          variant="outlined"
          fullWidth
          color="error"
          startIcon={<DeleteIcon />}
          onClick={() => handleDelete(image)}
        >
          Delete
        </Button>
      )}

      {done ? (
        <Alert severity="success">Succeed!</Alert>
      ) : (
        progress > 0 && <LinearProgressWithLabel value={progress} />
      )}
      {hasError && (
        <Alert
          severity="error"
          action={
            <Button color="inherit" size="small" onClick={handleUpload}>
              Retry
            </Button>
          }
        ></Alert>
      )}
    </div>
  );
};
export default ImageDemo;

import React, { useEffect, useState } from 'react';

import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth, db } from '../../firebase';
import {
  Alert,
  Button,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Stack,
} from '@mui/material';
import { AccountCircle, Visibility, VisibilityOff } from '@mui/icons-material';
import uuid from 'react-uuid';
import ImageDemo from './imageDemo';
import SendIcon from '@mui/icons-material/Send';
import { doc, setDoc, serverTimestamp } from 'firebase/firestore';

const Upload: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [user, setUser] = useState('');
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [images, setImages] = useState([]);
  const [imageDemo, setImageDemo] = useState([]);
  const [coverImage, setCoverImage] = useState([]);
  const [coverImageDemo, setCoverImageDemo] = useState([]);
  const [alarm, setAlarm] = useState([]);
  const [beginUpload, setBeginUpload] = useState(false);
  const [key] = useState(uuid());
  const [isCoverUploaded, setIsCoverUploaded] = useState(false);
  const [submitSuccess, setSubmitSucess] = useState(false);
  const [formInputs, setFormInputs] = useState({
    Description: '',
    Cover: '',
    timestamp: serverTimestamp(),
    Image: key,
    Sold: false,
    VIN: '',
    Make: '',
    MakeSearch: '',
    Model: '',
    ModelSearch: '',
    ModelYear: '',
    Exterior: '',
    Interior: '',
    PriceOrignial: 0,
    PriceDiscount: 0,
    EngineType: '',
    EngineDescription: '',
    Mileage: '',
    TransmissionType: '',
    FuelType: '',
    SeatingCapacity: '',
    ANDROIDAUTO: false,
    APPLECARPLAY: false,
    BACKUPCAMERA: false,
    ADAPTIVECRUISECONTROL: false,
    BLUETOOTH: false,
    HEATEDSEATS: false,
    KEYLESSENTRY: false,
    LANEDEPARTUREWARNING: false,
    MEMORYSEATS: false,
    NAVIGATIONSYSTEM: false,
    PARKINGSENSORSASSIST: false,
    POWERSEATS: false,
    RAINSENSINGWIPERS: false,
    SATELLITERADIOREADY: false,
    SIDEIMPACTAIRBAGS: false,
    SUNROOFMOONROOF: false,
    XENONHEADLIGHTS: false,
    BLINDSPOTMONITOR: false,
  });

  const handleCoverImageDownload = (url: string) => {
    setFormInputs((values) => ({ ...values, Cover: url }));
    setIsCoverUploaded(true);
  };
  const handleImageChange = (e) => {
    setImages([...images, ...e.target.files]);
    setBeginUpload(false);
  };

  const handleCoverImageChange = (e) => {
    setCoverImage([...coverImage, ...e.target.files]);
    setBeginUpload(false);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleLogin = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        setUser(userCredential.user.uid);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
      });
  };
  const handleLogout = () => {
    setUser('');
    auth.signOut();
  };
  const handleError = (error: string) => {
    setAlarm([...alarm, error]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setBeginUpload(true);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    const model = formInputs.Model;

    const obj = { [model]: '' };
    await setDoc(doc(db, 'filter', formInputs.Make), obj, { merge: true });

    await setDoc(doc(db, 'cars', key), formInputs).then(() => {
      setSubmitSucess(true);
      setTimeout(() => {
        setSubmitSucess(false);
      }, 10000);
    });
  };

  const handleAnother = () => {
    window.location.reload();
  };

  const handleDelete = (item: File) => {
    setImageDemo((current) => current.filter((demo) => demo.file !== item));
    setImages((current) => current.filter((image) => image !== item));
  };

  const handleCoverDelete = (item: File) => {
    setCoverImageDemo((current) =>
      current.filter((demo) => demo.file !== item)
    );
    setCoverImage((current) => current.filter((image) => image !== item));
  };

  const handleFormInputChange = (event) => {
    const name = event.target.name;
    if (name === 'PriceOrignial' || name === 'PriceDiscount') {
      const value = Number(event.target.value);
      setFormInputs((values) => ({ ...values, [name]: value }));
      return;
    }
    const value = event.target.value;
    setFormInputs((values) => ({ ...values, [name]: value }));
  };

  const handleFormCheckBoxChange = (event) => {
    const name = event.target.name;
    const checked = event.target.checked;
    setFormInputs((values) => ({ ...values, [name]: checked }));
  };

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) setUser(user?.uid);
    });
  }, []);

  useEffect(() => {
    if (images.length < 1) return;
    const temp = [];
    images.forEach((image) => {
      if (imageDemo.filter((e) => e.file === image).length === 0) {
        temp.push({ file: image, url: URL.createObjectURL(image) });
      }
    });
    setImageDemo((prev) => [...prev, ...temp]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images]);

  useEffect(() => {
    if (coverImage.length < 1) return;
    const temp = [];
    coverImage.forEach((image) => {
      if (coverImageDemo.filter((e) => e.file === image).length === 0) {
        temp.push({ file: image, url: URL.createObjectURL(image) });
      }
    });
    setCoverImageDemo([temp[0]]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coverImage]);

  useEffect(() => {
    if (formInputs.Model)
      setFormInputs((values) => ({
        ...values,
        ModelSearch: formInputs.Model.toUpperCase(),
      }));
    if (formInputs.Make)
      setFormInputs((values) => ({
        ...values,
        MakeSearch: formInputs.Make.toUpperCase(),
      }));
  }, [formInputs]);

  if (user) {
    return (
      <React.Fragment>
        <section className="user_profile inner_pages">
          <div className="container">
            {submitSuccess && (
              <Alert severity="success">Submit successfully!</Alert>
            )}
            <div className="row">
              <div className="col-md-2 col-sm-2">
                <div className="profile_nav">
                  <ul>
                    <li>
                      <Stack direction="column" spacing={6}>
                        <Button
                          onClick={() => setBeginUpload(true)}
                          variant="outlined"
                          size="large"
                          fullWidth
                          startIcon={<SendIcon />}
                        >
                          Upload
                        </Button>
                        <Button
                          color="error"
                          onClick={handleAnother}
                          variant="outlined"
                          size="medium"
                          fullWidth
                        >
                          Submit Another
                        </Button>
                        <Button
                          color="error"
                          onClick={handleLogout}
                          variant="outlined"
                          size="medium"
                          fullWidth
                        >
                          Sign Out
                        </Button>
                      </Stack>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-8 col-sm-8">
                <div className="profile_wrap">
                  <h5 className="uppercase underline">Post a New Vehicle</h5>
                  {alarm.length > 0 && (
                    <Stack sx={{ width: '100%' }} spacing={2}>
                      {alarm.map((error) => (
                        <Alert
                          severity="error"
                          onClose={() => {
                            setAlarm(alarm.filter((item) => item !== error));
                          }}
                        >
                          {error}
                        </Alert>
                      ))}
                    </Stack>
                  )}

                  <div className="form-group">
                    <label className="control-label">
                      Upload Cover Images ( size = 900 x 560 )
                    </label>

                    <div className="vehicle_images">
                      {coverImageDemo.length > 0 && (
                        <ImageDemo
                          uuid={key}
                          url={coverImageDemo[0].url}
                          image={coverImageDemo[0].file}
                          handleError={handleError}
                          beginUpload={beginUpload}
                          handleDelete={handleCoverDelete}
                          handleDownloadURL={handleCoverImageDownload}
                        />
                      )}
                      {coverImageDemo.length < 1 && (
                        <div className="upload_more_img">
                          <input
                            type="file"
                            multiple
                            accept="image/*"
                            onChange={handleCoverImageChange}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="control-label">
                      Upload Detail Images ( size = 900 x 560 )
                    </label>

                    <div className="vehicle_images">
                      {imageDemo.length > 0 &&
                        imageDemo.map((demo, index) => (
                          <ImageDemo
                            key={index}
                            uuid={key}
                            url={demo.url}
                            image={demo.file}
                            handleError={handleError}
                            beginUpload={beginUpload}
                            handleDelete={handleDelete}
                          />
                        ))}

                      <div className="upload_more_img">
                        <input
                          type="file"
                          multiple
                          accept="image/*"
                          onChange={handleImageChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="gray-bg field-title">
                    <h6>Basic Info</h6>
                  </div>
                  <form action="#" method="get">
                    <div className="form-group">
                      <label className="control-label">VIN</label>
                      <input
                        className="form-control white_bg"
                        type="text"
                        value={formInputs.VIN}
                        name="VIN"
                        onChange={handleFormInputChange}
                      />
                    </div>

                    <div className="form-group">
                      <label className="control-label">Make</label>
                      <input
                        className="form-control white_bg"
                        type="text"
                        value={formInputs.Make}
                        name="Make"
                        onChange={handleFormInputChange}
                      />
                    </div>
                    <div className="form-group">
                      <label className="control-label">Model</label>
                      <input
                        className="form-control white_bg"
                        type="text"
                        value={formInputs.Model}
                        name="Model"
                        onChange={handleFormInputChange}
                      />
                    </div>

                    <div className="form-group">
                      <label className="control-label">Model Year</label>
                      <input
                        className="form-control white_bg"
                        type="text"
                        value={formInputs.ModelYear}
                        name="ModelYear"
                        onChange={handleFormInputChange}
                      />
                    </div>

                    <div className="form-group">
                      <label className="control-label">Exterior</label>
                      <input
                        className="form-control white_bg"
                        type="text"
                        value={formInputs.Exterior}
                        name="Exterior"
                        onChange={handleFormInputChange}
                      />
                    </div>
                    <div className="form-group">
                      <label className="control-label">Interior</label>
                      <input
                        className="form-control white_bg"
                        type="text"
                        value={formInputs.Interior}
                        name="Interior"
                        onChange={handleFormInputChange}
                      />
                    </div>
                    <div className="form-group">
                      <label className="control-label">
                        Original Price ($)
                      </label>
                      <input
                        className="form-control white_bg"
                        type="text"
                        value={formInputs.PriceOrignial}
                        name="PriceOrignial"
                        onChange={handleFormInputChange}
                      />
                    </div>

                    <div className="form-group">
                      <label className="control-label">
                        Price After Discount($)
                      </label>
                      <input
                        className="form-control white_bg"
                        type="text"
                        value={formInputs.PriceDiscount}
                        name="PriceDiscount"
                        onChange={handleFormInputChange}
                      />
                    </div>
                    <div className="gray-bg field-title">
                      <h6>Technical Specification</h6>
                    </div>
                    <div className="form-group">
                      <label className="control-label">Engine Type</label>
                      <input
                        className="form-control white_bg"
                        type="text"
                        value={formInputs.EngineType}
                        name="EngineType"
                        onChange={handleFormInputChange}
                      />
                    </div>
                    <div className="form-group">
                      <label className="control-label">
                        Engine Description
                      </label>
                      <input
                        className="form-control white_bg"
                        type="text"
                        value={formInputs.EngineDescription}
                        name="EngineDescription"
                        onChange={handleFormInputChange}
                      />
                    </div>

                    <div className="form-group">
                      <label className="control-label">Mileage</label>
                      <input
                        className="form-control white_bg"
                        type="text"
                        value={formInputs.Mileage}
                        name="Mileage"
                        onChange={handleFormInputChange}
                      />
                    </div>

                    <div className="form-group">
                      <label className="control-label">Fuel Type</label>
                      <input
                        className="form-control white_bg"
                        type="text"
                        value={formInputs.FuelType}
                        name="FuelType"
                        onChange={handleFormInputChange}
                      />
                    </div>
                    <div className="form-group">
                      <label className="control-label">Seating Capacity</label>
                      <input
                        className="form-control white_bg"
                        type="text"
                        value={formInputs.SeatingCapacity}
                        name="SeatingCapacity"
                        onChange={handleFormInputChange}
                      />
                    </div>
                    <div className="form-group">
                      <label className="control-label">Transmission Type</label>
                      <input
                        className="form-control white_bg"
                        type="text"
                        value={formInputs.TransmissionType}
                        name="TransmissionType"
                        onChange={handleFormInputChange}
                      />
                    </div>

                    <div className="gray-bg field-title">
                      <h6>Car Description</h6>
                    </div>
                    <div className="form-group">
                      <label className="control-label">Description</label>
                      <textarea
                        className="form-control white_bg"
                        rows={4}
                        value={formInputs.Description}
                        name="Description"
                        onChange={handleFormInputChange}
                      />
                    </div>

                    <div className="gray-bg field-title">
                      <h6>Key Features</h6>
                    </div>
                    <div className="vehicle_accessories">
                      <div className="form-group checkbox col-md-6 accessories_list">
                        <input
                          id="ANDROIDAUTO"
                          type="checkbox"
                          name="ANDROIDAUTO"
                          onChange={handleFormCheckBoxChange}
                        />
                        <label htmlFor="ANDROIDAUTO">ANDROID AUTO</label>
                      </div>
                      <div className="form-group checkbox col-md-6 accessories_list">
                        <input
                          type="checkbox"
                          name="APPLECARPLAY"
                          id="APPLECARPLAY"
                          onChange={handleFormCheckBoxChange}
                        />
                        <label htmlFor="APPLECARPLAY">APPLE CARPLAY</label>
                      </div>
                      <div className="form-group checkbox col-md-6 accessories_list">
                        <input
                          id="BACKUPCAMERA"
                          type="checkbox"
                          name="BACKUPCAMERA"
                          onChange={handleFormCheckBoxChange}
                        />
                        <label htmlFor="BACKUPCAMERA">BACKUP CAMERA</label>
                      </div>
                      <div className="form-group checkbox col-md-6 accessories_list">
                        <input
                          id="ADAPTIVECRUISECONTROL"
                          type="checkbox"
                          name="ADAPTIVECRUISECONTROL"
                          onChange={handleFormCheckBoxChange}
                        />
                        <label htmlFor="ADAPTIVECRUISECONTROL">
                          ADAPTIVE CRUISE CONTROL
                        </label>
                      </div>
                      <div className="form-group checkbox col-md-6 accessories_list">
                        <input
                          id="BLUETOOTH"
                          type="checkbox"
                          name="BLUETOOTH"
                          onChange={handleFormCheckBoxChange}
                        />
                        <label htmlFor="BLUETOOTH">BLUETOOTH</label>
                      </div>
                      <div className="form-group checkbox col-md-6 accessories_list">
                        <input
                          id="ADAPTIVCRUISECONTROL"
                          name="ADAPTIVCRUISECONTROL"
                          onChange={handleFormCheckBoxChange}
                          type="checkbox"
                        />
                        <label htmlFor="ADAPTIVCRUISECONTROL">
                          ADAPTIVE CRUISE CONTROL
                        </label>
                      </div>
                      <div className="form-group checkbox col-md-6 accessories_list">
                        <input
                          id="BLINDSPOTMONITOR"
                          name="BLINDSPOTMONITOR"
                          onChange={handleFormCheckBoxChange}
                          type="checkbox"
                        />
                        <label htmlFor="BLINDSPOTMONITOR">
                          BLINDSPOT MONITOR
                        </label>
                      </div>
                      <div className="form-group checkbox col-md-6 accessories_list">
                        <input
                          id="HEATEDSEATS"
                          name="HEATEDSEATS"
                          onChange={handleFormCheckBoxChange}
                          type="checkbox"
                        />
                        <label htmlFor="HEATEDSEATS">HEATED SEATS</label>
                      </div>
                      <div className="form-group checkbox col-md-6 accessories_list">
                        <input
                          id="MEMORYSEATS"
                          name="MEMORYSEATS"
                          onChange={handleFormCheckBoxChange}
                          type="checkbox"
                        />
                        <label htmlFor="MEMORYSEATS">MEMORY SEATS</label>
                      </div>
                      <div className="form-group checkbox col-md-6 accessories_list">
                        <input
                          id="POWERSEATS"
                          name="POWERSEATS"
                          onChange={handleFormCheckBoxChange}
                          type="checkbox"
                        />
                        <label htmlFor="POWERSEATS">POWER SEATS</label>
                      </div>
                      <div className="form-group checkbox col-md-6 accessories_list">
                        <input
                          id="KEYLESSENTRY"
                          name="KEYLESSENTRY"
                          onChange={handleFormCheckBoxChange}
                          type="checkbox"
                        />
                        <label htmlFor="KEYLESSENTRY">KEYLESS ENTRY</label>
                      </div>
                      <div className="form-group checkbox col-md-6 accessories_list">
                        <input
                          id="LANEDEPARTUREWARNING"
                          name="LANEDEPARTUREWARNING"
                          onChange={handleFormCheckBoxChange}
                          type="checkbox"
                        />
                        <label htmlFor="LANEDEPARTUREWARNING">
                          LANE DEPARTURE WARNING
                        </label>
                      </div>

                      <div className="form-group checkbox col-md-6 accessories_list">
                        <input
                          id="NAVIGATIONSYSTEM"
                          name="NAVIGATIONSYSTEM"
                          onChange={handleFormCheckBoxChange}
                          type="checkbox"
                        />
                        <label htmlFor="NAVIGATIONSYSTEM">
                          NAVIGATION SYSTEM
                        </label>
                      </div>
                      <div className="form-group checkbox col-md-6 accessories_list">
                        <input
                          id="PARKINGSENSORSASSIST"
                          name="PARKINGSENSORSASSIST"
                          onChange={handleFormCheckBoxChange}
                          type="checkbox"
                        />
                        <label htmlFor="PARKINGSENSORSASSIST">
                          PARKING SENSORS / ASSIST
                        </label>
                      </div>

                      <div className="form-group checkbox col-md-6 accessories_list">
                        <input
                          id="RAINSENSINGWIPERS"
                          name="RAINSENSINGWIPERS"
                          onChange={handleFormCheckBoxChange}
                          type="checkbox"
                        />
                        <label htmlFor="RAINSENSINGWIPERS">
                          RAIN SENSING WIPERS
                        </label>
                      </div>
                      <div className="form-group checkbox col-md-6 accessories_list">
                        <input
                          id="SATELLITERADIOREADY"
                          name="SATELLITERADIOREADY"
                          onChange={handleFormCheckBoxChange}
                          type="checkbox"
                        />
                        <label htmlFor="SATELLITERADIOREADY">
                          SATELLITE RADIO READY
                        </label>
                      </div>
                      <div className="form-group checkbox col-md-6 accessories_list">
                        <input
                          id="SIDEIMPACTAIRBAGS"
                          name="SIDEIMPACTAIRBAGS"
                          onChange={handleFormCheckBoxChange}
                          type="checkbox"
                        />
                        <label htmlFor="SIDEIMPACTAIRBAGS">
                          SIDE-IMPACT AIR BAGS
                        </label>
                      </div>
                      <div className="form-group checkbox col-md-6 accessories_list">
                        <input
                          id="SUNROOFMOONROOF"
                          name="SUNROOFMOONROOF"
                          onChange={handleFormCheckBoxChange}
                          type="checkbox"
                        />
                        <label htmlFor="SUNROOFMOONROOF">
                          SUNROOF / MOONROOF
                        </label>
                      </div>
                      <div className="form-group checkbox col-md-6 accessories_list">
                        <input
                          id="XENONHEADLIGHTS"
                          name="XENONHEADLIGHTS"
                          onChange={handleFormCheckBoxChange}
                          type="checkbox"
                        />
                        <label htmlFor="XENONHEADLIGHTS">
                          XENON HEADLIGHTS
                        </label>
                      </div>
                    </div>

                    <div className="form-group">
                      <button
                        type="submit"
                        className="btn"
                        onClick={handleSubmit}
                        disabled={!isCoverUploaded}
                      >
                        Submit Vehicle{' '}
                        <span className="angle_arrow">
                          <i
                            className="fa fa-angle-right"
                            aria-hidden="true"
                          ></i>
                        </span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <section className="user_profile inner_pages">
        <div className="container">
          <div className="user_profile_info gray-bg padding_4x4_40">
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              rowSpacing={12}
              columnSpacing={2}
            >
              <Grid item xs={6}>
                <FormControl variant="standard">
                  <InputLabel htmlFor="input-with-icon-adornment">
                    User name
                  </InputLabel>
                  <Input
                    id="input-with-icon-adornment"
                    startAdornment={
                      <InputAdornment position="start">
                        <AccountCircle />
                      </InputAdornment>
                    }
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="standard">
                  <InputLabel htmlFor="outlined-adornment-password">
                    Password
                  </InputLabel>
                  <Input
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </FormControl>
              </Grid>

              <Grid item>
                <Button variant="contained" onClick={handleLogin}>
                  Login
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Upload;

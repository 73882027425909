export const PowerSeats: React.FC = () => {
  return (
    <svg
      className="di-svg"
      fill="#000000"
      height="48"
      viewBox="0 0 48 48"
      width="48"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="presentation"
      tabIndex={-1}
    >
      <path
        d="M16.691,45.562h18.167c1.507,0,2.902-0.789,3.677-2.081l3.294-5.489c0,0,1.361-3.938-2.574-3.634
		c-3.938,0.303-14.689,1.211-20.594,2.422c0,0,1.426-13.146-3.283-19.667c-0.661-0.915-1.707-1.473-2.834-1.532
		c-0.68-0.037-1.509-0.072-2.354-0.092c-2.233-0.05-4.006,1.858-3.809,4.083c0.406,4.555,1.829,12.863,6.627,23.592
		C13.657,44.619,15.097,45.562,16.691,45.562"
        tabIndex={-1}
      ></path>
      <path
        d="M10.533,14.448L10.533,14.448c1.979,0.338,3.877-1.002,4.215-2.983l0.823-4.797c0.339-1.979-1.002-3.877-2.982-4.216
		C10.61,2.112,8.712,3.455,8.374,5.434l-0.823,4.799C7.211,12.211,8.554,14.109,10.533,14.448"
        tabIndex={-1}
      ></path>
      <path
        d="M34,16.25v-4h-2v4h-4v-4h-2v4l0,0c-1,0-2,1-2,2v5.5l3.5,3.5v3h5v-3l3.5-3.5v-5.5C36,17.25,35,16.25,34,16.25z"
        tabIndex={-1}
      ></path>
    </svg>
  );
};

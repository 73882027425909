import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './components/home/home';
import Inventory from './components/inventory/inventory';
import Navigation from './components/navigation/navigation';
import Footer from './components/footer/footer';
import CarDetail from './components/carDetail/carDetail';
import ContactUs from './components/contactUs/contactUs';
import Upload from './components/upload/upload';
import Services from './components/services/services';
import Wrapping from './components/wrapping/wrapping';
import AutoSPA from './components/autoSpa/autoSPA';
import AboutUs from './components/aboutUs/aboutUs';
import { PageNotFound } from './components/404/pageNotFound';

function App() {
  return (
    <div>
      <Navigation />
      <Routes>
        <Route path="*" element={<PageNotFound />} />
        <Route path="/" element={<Home />} />
        <Route path="/carDetail" element={<CarDetail />} />
        <Route path="/inventory" element={<Inventory />} />
        <Route path="/contactUs" element={<ContactUs />} />
        <Route path="/services" element={<Services />} />
        <Route path="/wrapping" element={<Wrapping />} />
        <Route path="/autoSPA" element={<AutoSPA />} />
        <Route path="/aboutUs" element={<AboutUs />} />
        <Route path="/admin" element={<Upload />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;

export const SatelliteRadio: React.FC = () => {
  return (
    <svg
      className="di-svg"
      fill="#000000"
      height="48"
      viewBox="0 0 48 48"
      width="48"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="presentation"
      tabIndex={-1}
    >
      <g tabIndex={-1}>
        <path
          d="M34.562,23.113c-7.62-3.705-14.618,3.428-10.956,10.945L34.562,23.113z"
          tabIndex={-1}
        ></path>
        <path
          d="M30.616,30.143c0.682-0.689,0.455-2.012-0.5-2.959l-2.445,2.445C28.622,30.594,29.944,30.824,30.616,30.143z"
          tabIndex={-1}
        ></path>
        <path
          d="M16.171,20.842c-1.418-1.434-1.418-3.732,0-5.182l0,0c1.431-1.398,3.744-1.398,5.148,0l3.637,3.637
                  c1.422,1.43,1.422,3.738,0,5.17l0,0c-1.428,1.418-3.727,1.418-5.152,0L16.171,20.842z"
          tabIndex={-1}
        ></path>
        <path
          d="M14.652,14.156c1.422-1.441,3.721-1.441,5.152,0l-3.156-3.151c-1.418-1.418-3.715-1.418-5.141,0
                  c-1.426,1.41-1.426,3.71,0,5.147l3.145,3.145C13.23,17.891,13.23,15.574,14.652,14.156z"
          tabIndex={-1}
        ></path>
        <g tabIndex={-1}>
          <path
            d="M21.872,13.307c-0.495-0.477-0.495-1.288,0-1.79l8.155-8.15c0.494-0.488,1.287-0.488,1.788,0l5.317,5.32
                      c0.49,0.503,0.49,1.292,0,1.779l-8.176,8.161c-0.481,0.498-1.27,0.498-1.767,0L21.872,13.307z"
            tabIndex={-1}
          ></path>
          <path
            d="M3.876,31.306c-0.501-0.479-0.501-1.283,0-1.778l8.146-8.16c0.487-0.49,1.309-0.49,1.785,0l5.317,5.335
                      c0.501,0.471,0.501,1.274,0,1.77l-8.167,8.159c-0.486,0.491-1.289,0.491-1.779,0L3.876,31.306z"
            tabIndex={-1}
          ></path>
        </g>
      </g>
      <path
        d="M34.124,29c0.239,0.004,0.434,0.098,0.577,0.271c0.279,0.332,0.325,0.895,0.309,1.148c-0.092,1.598-0.902,3.028-2.168,3.839
              c-0.675,0.428-1.441,0.656-2.221,0.656c-0.081,0-0.162-0.006-0.242-0.009c-0.251-0.016-0.717-0.176-0.971-0.479
              c-0.15-0.18-0.211-0.385-0.188-0.627c0.014-0.129,0.072-0.236,0.171-0.32c0.229-0.197,0.578-0.213,0.68-0.213
              c0.056,0,0.11,0.007,0.159,0.012c0.131,0.018,0.266,0.029,0.396,0.029c0.521,0,1.015-0.148,1.47-0.438
              c0.977-0.621,1.543-1.818,1.441-3.049c-0.013-0.168,0.098-0.443,0.254-0.629C33.898,29.07,34.018,29,34.119,29H34.124z"
        tabIndex={-1}
      ></path>
      <path
        d="M37.857,29.051c0.021,0,0.042,0.001,0.062,0.002l0.039,0.004c0.213,0.016,0.425,0.135,0.582,0.324
              c0.137,0.166,0.22,0.371,0.231,0.576v0.011l-0.004,0.048c-0.003,0.075-0.014,0.232-0.036,0.545c-0.226,2.977-1.765,5.646-4.12,7.147
              c-1.283,0.818-2.743,1.248-4.225,1.248c-0.136,0-0.271-0.003-0.405-0.012L29.5,38.922l-0.008-0.005
              c-0.117-0.022-0.278-0.137-0.389-0.268c-0.16-0.192-0.249-0.447-0.242-0.694v-0.053c0.008-0.219,0.084-0.408,0.215-0.518
              c0.076-0.062,0.17-0.099,0.271-0.099c0.033,0,0.064,0.003,0.098,0.012l0.498,0.039c0.148,0.007,0.297,0.015,0.445,0.015
              c1.221,0,2.424-0.355,3.479-1.027c2.018-1.285,3.293-3.578,3.41-6.133l0.021-0.484c-0.014-0.216,0.026-0.364,0.129-0.479
              C37.53,29.111,37.682,29.051,37.857,29.051z"
        tabIndex={-1}
      ></path>
      <path
        d="M41.658,29.104c0.02,0,0.039,0.001,0.057,0.002c0.221,0.017,0.415,0.122,0.565,0.302c0.138,0.166,0.217,0.372,0.22,0.58
              v0.01l-0.001,0.017c-0.001,0.038-0.009,0.173-0.036,0.556c-0.318,4.406-2.59,8.367-6.08,10.588C34.494,42.361,32.342,43,30.156,43
              c-0.198,0-0.398-0.004-0.597-0.015l-0.448-0.024c-0.133-0.019-0.275-0.111-0.384-0.244c-0.151-0.182-0.233-0.428-0.228-0.674
              c0.008-0.262,0.089-0.477,0.228-0.604c0.075-0.068,0.161-0.105,0.251-0.105c0.018,0,0.037,0.001,0.057,0.008l0.428,0.029
              c0.232,0.015,0.467,0.024,0.699,0.024c1.92,0,3.814-0.56,5.477-1.621c3.104-1.979,5.109-5.5,5.361-9.423l0.031-0.529
              c-0.003-0.219,0.059-0.402,0.184-0.535C41.33,29.167,41.487,29.104,41.658,29.104z"
        tabIndex={-1}
      ></path>
    </svg>
  );
};

export const SideImpact: React.FC = () => {
  return (
    <svg
      className="di-svg"
      fill="#000000"
      height="48"
      viewBox="0 0 48 48"
      width="48"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="presentation"
      tabIndex={-1}
    >
      <path
        d="M32.8,16.5c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7c-2.6,0-4.7,2.1-4.7,4.7C28.1,14.4,30.2,16.5,32.8,16.5z"
        tabIndex={-1}
      ></path>
      <path
        d="M20,27.7c-1.1-0.4-2.2-0.7-3.2-1.1c-1.1-0.4-2.4,0.2-3,1.2c-2.1,3.4-4.1,6.8-6.2,10.1c-1.8,2.9,2.8,5.6,4.6,2.7
		c1.7-2.8,3.4-5.5,5.1-8.3c1.9,0.6,3.7,1.3,5.6,1.9c-0.8-1.5-1.6-3-2.5-4.6C20.1,29,19.9,28.3,20,27.7z"
        tabIndex={-1}
      ></path>
      <path
        d="M32.5,17.7c-1.2-0.7-3-0.3-3.6,1c-1.2,2.3-2.4,4.6-3.5,6.9c0.3,0.2,0.5,0.5,0.7,0.9l1.9,3.5c0.2,0.3,0.4,0.7,0.6,1
		c1.7-3.2,3.3-6.5,5-9.7c0.1-0.2,0.2-0.5,0.2-0.7c0.2-0.8,0-1.4-0.3-2C33.2,18.2,32.9,17.9,32.5,17.7z"
        tabIndex={-1}
      ></path>
      <path
        d="M21.9,28.8c2.3,4.3,4.2,7.8,6.5,12.1c0.3,0.5,0.8,0.8,1.2,0.8c1,0,2.1-1.1,1.4-2.3c-2.3-4.3-4.2-7.8-6.5-12.1
		c-0.3-0.5-0.7-0.8-1.2-0.8C22.3,26.5,21.2,27.6,21.9,28.8z"
        tabIndex={-1}
      ></path>
      <path
        d="M39.6,15.3c-2.1,1.2-1.7,1-3.8,2.1c-0.3,0.1-0.4,0.3-0.6,0.5c0.2,0.6,0.3,1.2,0.2,1.8c0.4,0.5,1.1,0.7,1.9,0.3
		c2-1.2,1.7-1,3.8-2.1C42.8,16.9,41.3,14.3,39.6,15.3z"
        tabIndex={-1}
      ></path>
      <path
        d="M24,15.1c0,4.9-4,8.9-8.9,8.9c-4.9,0-8.9-4-8.9-8.9c0-4.9,4-8.9,8.9-8.9C20,6.1,24,10.1,24,15.1z"
        tabIndex={-1}
      ></path>
    </svg>
  );
};

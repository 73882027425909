export const MemorySeats: React.FC = () => {
  return (
    <svg
      className="di-svg"
      fill="#000000"
      height="48"
      viewBox="0 0 48 48"
      width="48"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="presentation"
      tabIndex={-1}
    >
      <path
        d="M16.691,45.562h18.167c1.507,0,2.902-0.789,3.677-2.081l3.294-5.489c0,0,1.361-3.938-2.574-3.634
		c-3.938,0.303-14.689,1.211-20.594,2.422c0,0,1.426-13.146-3.283-19.667c-0.661-0.915-1.707-1.473-2.834-1.532
		c-0.68-0.037-1.509-0.072-2.354-0.092c-2.233-0.05-4.006,1.858-3.809,4.083c0.406,4.555,1.829,12.863,6.627,23.592
		C13.657,44.619,15.097,45.562,16.691,45.562"
        tabIndex={-1}
      ></path>
      <path
        d="M10.533,14.448L10.533,14.448c1.979,0.338,3.877-1.002,4.215-2.983l0.823-4.797c0.339-1.979-1.002-3.877-2.982-4.216
		C10.61,2.112,8.712,3.455,8.374,5.434l-0.823,4.799C7.211,12.211,8.554,14.109,10.533,14.448"
        tabIndex={-1}
      ></path>
      <path
        d="M30,13c-4.971,0-9,4.029-9,9c0,4.971,4.029,9,9,9c4.97,0,9-4.029,9-9C39,17.029,34.97,13,30,13z M31.688,26.78
		c0,0.933-0.757,1.688-1.688,1.688s-1.688-0.755-1.688-1.688v-5.061c0-0.932,0.757-1.688,1.688-1.688s1.688,0.756,1.688,1.688V26.78z
		 M30,18.906c-0.931,0-1.688-0.754-1.688-1.686c0-0.933,0.757-1.689,1.688-1.689s1.688,0.756,1.688,1.689
		C31.688,18.152,30.931,18.906,30,18.906z"
        tabIndex={-1}
      ></path>
    </svg>
  );
};

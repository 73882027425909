import wrapping1_1 from '../../images/wrapping1_1.jpeg';
import wrapping1_2 from '../../images/wrapping1_2.jpeg';
import wrapping1_3 from '../../images/wrapping1_3.jpeg';
import wrapping2_1 from '../../images/wrapping2_1.jpeg';
import wrapping2_2 from '../../images/wrapping2_2.jpeg';
import wrapping2_3 from '../../images/wrapping2_3.jpeg';
import wrapping2_4 from '../../images/wrapping2_4.jpeg';
import window1_1 from '../../images/window1_1.jpeg';
import window1_2 from '../../images/window1_2.jpeg';
import './wrapping.css';
import { Link } from 'react-router-dom';

const Wrapping: React.FC = () => {
  return (
    <section id="services" className="section-padding">
      <div className="container">
        <div className="section-header text-center">
          <h2>
            Welcome <span>to the Wrapping</span>
          </h2>
          <p>We strive to provide the best service possible </p>
          <Link
            to={{
              pathname: '/contactUs',
              search: `?subject=wrapping`,
            }}
            className="btn"
          >
            REQUEST A QUOTE{' '}
            <span className="angle_arrow">
              <i className="fa fa-angle-right" aria-hidden="true"></i>
            </span>
          </Link>{' '}
        </div>
        <div className="row">
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-4">
                <div className="services_image">
                  {' '}
                  <img src={wrapping1_1} alt="image" />
                </div>
              </div>
              <div className="col-md-4">
                <div className="services_image">
                  {' '}
                  <img src={wrapping1_2} alt="image" />
                </div>
              </div>
              <div className="col-md-4">
                <div className="services_image">
                  {' '}
                  <img src={wrapping1_3} alt="image" />
                </div>
              </div>
            </div>
            <div className="space-20"></div>
            <div className="row">
              <div className="col-md-3">
                <div className="services_image">
                  {' '}
                  <img src={wrapping2_1} alt="image" />
                </div>
              </div>
              <div className="col-md-3">
                <div className="services_image">
                  {' '}
                  <img src={wrapping2_2} alt="image" />
                </div>
              </div>
              <div className="col-md-3">
                <div className="services_image">
                  {' '}
                  <img src={wrapping2_3} alt="image" />
                </div>
              </div>
              <div className="col-md-3">
                <div className="services_image">
                  {' '}
                  <img src={wrapping2_4} alt="image" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="wrapping-card">
              <h4>Benefits of wrapping</h4>
            </div>
            <ul className="list_style_none">
              <li>
                <i className="fa fa-check" aria-hidden="true"></i> On the
                premise of not damaging the original car paint COMPLETELY CHANGE
                ANY COLOR & DESGIN OF YOUR CAR.
              </li>
              <li>
                <i className="fa fa-check" aria-hidden="true"></i> Compared with
                the whole car painting, the modification film is easy to
                construct and better to protect the integrity of the vehicle;
                Color collocation autonomy is stronger, and there will be no
                color difference in different parts of the same color trouble.
              </li>
              <li>
                <i className="fa fa-check" aria-hidden="true"></i> Protect the
                original car paint, tear the film at will at any time.
              </li>
            </ul>
          </div>
        </div>

        <div className="section-header text-center">
          <h2>WINDOW TINTING</h2>
        </div>
        <div className="row">
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-6">
                <div className="services_image">
                  {' '}
                  <img src={window1_1} alt="image" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="services_image">
                  {' '}
                  <img src={window1_2} alt="image" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="wrapping-card">
              <h4>Benefits of window tinting</h4>
            </div>
            <ul className="list_style_none">
              <li>
                <i className="fa fa-check" aria-hidden="true"></i>
                privacy
              </li>
              <li>
                <i className="fa fa-check" aria-hidden="true"></i> Blocking
                harmful UV rays which can fade your interior
              </li>
              <li>
                <i className="fa fa-check" aria-hidden="true"></i> Reduced glare
                in your eyes
              </li>
              <li>
                <i className="fa fa-check" aria-hidden="true"></i> Cleaner,more
                uniform look to your car
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Wrapping;

import aboutUs1_1 from '../../images/aboutUs1_1.jpeg';
import aboutUs1_2 from '../../images/aboutUs1_2.jpeg';
import aboutUs2_1 from '../../images/aboutUs2_1.jpeg';
import aboutUs2_2 from '../../images/aboutUs2_2.jpeg';
import aboutUs3_1 from '../../images/aboutUs3_1.jpeg';
import './aboutUs.css';
const AboutUs: React.FC = () => {
  return (
    <section id="services" className="section-padding">
      <div className="container">
        <div className="section-header text-center">
          <h2>WHY CHOOSE SR AUTO GROUP?</h2>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="services_image">
              {' '}
              <img src={aboutUs1_1} alt="image1" />
              <div className="service_heading white-text">
                <h3>Best Services</h3>
              </div>
            </div>
            <ul className="list_style_none">
              <li>
                <h3 className="aboutUs-text">
                  <i className="fa fa-check" aria-hidden="true"></i>
                  We provide a Multi range of services - buy&sell cars, repair
                  &service, car warp & detailing
                </h3>
              </li>
            </ul>
          </div>
          <div className="col-md-6 col-md-push-6">
            <div className="services_image">
              {' '}
              <img src={aboutUs1_2} alt="image2" />
              <div className="service_heading white-text">
                <h3>Best Inventory</h3>
              </div>
            </div>
            <ul className="list_style_none">
              <li>
                <h3 className="aboutUs-text">
                  <i className="fa fa-check" aria-hidden="true"></i> We have
                  best inventory with unbeatable price.
                </h3>
              </li>
            </ul>
          </div>
        </div>
        <div className="space-40"></div>
        <div className="row">
          <div className="col-md-6">
            <div className="services_image">
              {' '}
              <img src={aboutUs2_1} alt="image3" />
              <img src={aboutUs2_2} alt="image4" />
              <div className="service_heading white-text">
                <h3>Nationwide home delivery</h3>
              </div>
            </div>
            <ul className="list_style_none">
              <li>
                <h3 className="aboutUs-text">
                  <i className="fa fa-check" aria-hidden="true"></i>We provide
                  nationwide delivery
                </h3>
              </li>
            </ul>
          </div>
          <div className="col-md-6 col-md-push-6">
            <div className="services_image">
              {' '}
              <img src={aboutUs3_1} alt="image5" />
              <div className="service_heading white-text">
                <h3>Multiple stores</h3>
              </div>
            </div>
            <h3 className="aboutUs-center">MASSACHUSETTS:</h3>
            <ul className="list_style_none aboutUs-center">
              <li>
                <h4 className="aboutUs-text">
                  <i className="fa fa-check" aria-hidden="true"></i>{' '}
                  <a
                    className="aboutUs-storeName"
                    target="_blank"
                    href="http://maps.google.com/?q=SR Auto Group Massachusetts, Quincy, W Howard St,02169"
                    rel="noreferrer"
                  >
                    SR Auto Group 36 W Howard St Quincy MA 02169
                  </a>
                </h4>
              </li>
            </ul>
            <h3 className="aboutUs-center">NEW YORK:</h3>
            <ul className="list_style_none aboutUs-center">
              <li>
                <h4 className="aboutUs-text">
                  <i className="fa fa-check" aria-hidden="true"></i> 202
                  NORTHERN BLVD FLUSHING NY 11501
                </h4>
              </li>
            </ul>{' '}
            <ul className="list_style_none aboutUs-center">
              <li>
                <h3>LONG ISLAND:</h3>
              </li>
              <li>
                <h4 className="aboutUs-text">
                  <i className="fa fa-check" aria-hidden="true"></i> 30 DOYLE ST
                  STE4 ST JAMES NY 11780
                </h4>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;

export const HeatedSeat: React.FC = () => {
  return (
    <svg
      className="di-svg"
      fill="#000000"
      height="48"
      viewBox="0 0 48 48"
      width="48"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="presentation"
      tabIndex={-1}
    >
      <path
        d="M16.691,45.562h18.167c1.507,0,2.902-0.789,3.677-2.081l3.294-5.489c0,0,1.361-3.938-2.574-3.634
	c-3.938,0.303-14.689,1.211-20.594,2.422c0,0,1.426-13.146-3.283-19.667c-0.661-0.915-1.707-1.473-2.834-1.532
	c-0.68-0.037-1.509-0.072-2.354-0.092c-2.233-0.05-4.006,1.858-3.809,4.083c0.406,4.555,1.829,12.863,6.627,23.592
	C13.657,44.619,15.097,45.562,16.691,45.562"
        tabIndex={-1}
      ></path>
      <path
        d="M10.533,14.448L10.533,14.448c1.979,0.338,3.877-1.002,4.215-2.983l0.823-4.797c0.339-1.979-1.002-3.877-2.982-4.216
		C10.61,2.112,8.712,3.455,8.374,5.434l-0.823,4.799C7.211,12.211,8.554,14.109,10.533,14.448"
        tabIndex={-1}
      ></path>
      <path
        d="M24.226,13.235c-0.429,1.667-0.799,3.248-0.762,4.649c0.023,0.37,0.038,0.627,0.129,0.878c0.08,0.262,0.214,0.551,0.39,0.84
		c0.338,0.602,0.811,1.22,1.291,1.888c0.479,0.672,0.992,1.378,1.404,2.25c0.209,0.433,0.385,0.923,0.479,1.471
		c0.047,0.271,0.076,0.559,0.062,0.847c-0.016,0.176-0.024,0.211-0.037,0.355c-0.019,0.133-0.038,0.269-0.062,0.382
		c-0.196,0.956-0.584,1.79-1.072,2.534c-0.499,0.738-1.089,1.394-1.822,1.901c0.196-0.861,0.388-1.658,0.524-2.44
		c0.134-0.775,0.224-1.531,0.195-2.219c0.005-0.092-0.006-0.16-0.01-0.233c-0.005-0.061-0.014-0.233-0.023-0.262
		c-0.02-0.115-0.037-0.239-0.08-0.367c-0.077-0.262-0.215-0.543-0.378-0.843c-0.338-0.592-0.795-1.22-1.27-1.889
		c-0.475-0.674-0.974-1.392-1.387-2.259c-0.194-0.445-0.38-0.927-0.47-1.467c-0.095-0.556-0.052-1.13,0.052-1.582
		c0.19-0.945,0.584-1.781,1.063-2.521C22.937,14.412,23.516,13.752,24.226,13.235z"
        tabIndex={-1}
      ></path>
      <path
        d="M29.878,13.235c-0.428,1.667-0.799,3.248-0.761,4.649c0.024,0.37,0.038,0.627,0.128,0.878
		c0.082,0.262,0.215,0.551,0.392,0.84c0.335,0.602,0.807,1.22,1.285,1.888c0.479,0.672,0.994,1.378,1.407,2.25
		c0.208,0.433,0.384,0.923,0.479,1.471c0.047,0.271,0.076,0.559,0.062,0.847c-0.015,0.176-0.023,0.211-0.038,0.355
		c-0.02,0.133-0.037,0.269-0.061,0.382c-0.195,0.956-0.585,1.79-1.073,2.534c-0.499,0.738-1.089,1.394-1.819,1.901
		c0.194-0.861,0.384-1.658,0.521-2.44c0.135-0.775,0.225-1.531,0.197-2.219c0.004-0.092-0.006-0.16-0.011-0.233
		c-0.004-0.061-0.015-0.233-0.023-0.262c-0.019-0.115-0.038-0.239-0.081-0.367c-0.076-0.262-0.213-0.543-0.375-0.843
		c-0.337-0.592-0.794-1.22-1.269-1.889c-0.474-0.674-0.974-1.392-1.387-2.259c-0.195-0.445-0.38-0.927-0.472-1.467
		c-0.095-0.556-0.052-1.13,0.053-1.582c0.19-0.945,0.585-1.781,1.063-2.521C28.59,14.412,29.169,13.752,29.878,13.235z"
        tabIndex={-1}
      ></path>
      <path
        d="M35.527,13.235c-0.428,1.667-0.799,3.248-0.761,4.649c0.023,0.369,0.038,0.627,0.129,0.878
		c0.079,0.262,0.214,0.55,0.388,0.839c0.338,0.602,0.809,1.221,1.288,1.889c0.479,0.672,0.994,1.378,1.406,2.25
		c0.207,0.433,0.385,0.923,0.479,1.471c0.047,0.271,0.075,0.559,0.062,0.847c-0.015,0.176-0.023,0.211-0.038,0.355
		c-0.02,0.133-0.038,0.269-0.062,0.382c-0.196,0.956-0.586,1.79-1.074,2.534c-0.497,0.738-1.087,1.394-1.817,1.901
		c0.195-0.861,0.384-1.658,0.521-2.44c0.134-0.775,0.224-1.531,0.195-2.219c0.005-0.092-0.004-0.16-0.009-0.233
		c-0.006-0.061-0.015-0.233-0.023-0.262c-0.02-0.115-0.038-0.239-0.081-0.367c-0.077-0.262-0.214-0.543-0.376-0.843
		c-0.338-0.592-0.792-1.22-1.268-1.889c-0.476-0.674-0.974-1.392-1.387-2.259c-0.194-0.445-0.379-0.927-0.47-1.466
		c-0.096-0.557-0.053-1.132,0.052-1.583c0.19-0.946,0.585-1.781,1.063-2.521C34.24,14.412,34.818,13.752,35.527,13.235z"
        tabIndex={-1}
      ></path>
    </svg>
  );
};

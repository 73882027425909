import {
  Alert,
  FormControl,
  Input,
  InputAdornment,
  MenuItem,
  Select,
} from '@mui/material';
import { doc, setDoc } from 'firebase/firestore';
import React, { useCallback, useEffect, useState } from 'react';
import { IMaskInput } from 'react-imask';
import { db } from '../../firebase';
import uuid from 'react-uuid';
import { generateHtml } from './generateHtml';
import { useLocation, useSearchParams } from 'react-router-dom';
import './contactUs.css';
const ContactUs: React.FC = () => {
  const uid = uuid();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [subject, setSubject] = useState('');
  const [messageTips, setMessageTips] = useState('');
  const [alert, setAlert] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [searchParams] = useSearchParams();
  const type = searchParams.get('subject');
  const location = useLocation();
  const defaultInputs = {
    Name: '',
    Email: '',
    Message: '',
    Make: '',
    YearL: '',
    YearH: '',
    MileageL: '',
    MileageH: '',
    PriceL: '',
    PriceH: '',
    ExteriorColor: '',
    InteriorColor: '',
    VIN: '',
    Mileage: '',
  };
  const [formInputs, setFormInputs] = useState(defaultInputs);

  const handleFormInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setFormInputs((values) => ({ ...values, [name]: value }));
    setAlert('');
  };

  const reset = () => {
    setAlert('');
    setError(false);
    setSuccess(false);
    setFormInputs(defaultInputs);
    setPhoneNumber('');
  };

  const handleSubjectChange = useCallback((value: string) => {
    switch (value) {
      case 'Buy car':
        setSubject('Buy car');
        setMessageTips('I want buy the car, car make is: xxx');
        break;
      case 'Sell car':
        setSubject('Sell car');
        setMessageTips(
          'Describe your car, car make is: xxx, mileage: xxx, price: xxx ...'
        );
        break;
      case 'Wrap car':
        setSubject('Wrap car');
        setMessageTips('I want wrap my car, car make is: xxx');
        break;
      case 'Request service':
        setSubject('Request service');
        setMessageTips('I want request service, car make is: xxx');
        break;
      case 'Auto SPA':
        setSubject('Auto SPA');
        setMessageTips(
          'I want request a Auto SPA, car type: car/SUV, at 2023/01/01'
        );
        break;

      case 'Find your dream car':
        setSubject('Find your dream car');
        setMessageTips(
          'I want you guys help me find the car, requirement: xxx ...'
        );
        break;
    }
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    window.scrollTo({ top: 1000, behavior: 'smooth' });
    if (!subject) {
      setAlert('Subject');
      return;
    }
    if (!formInputs.Name) {
      setAlert('Full name');
      return;
    }
    if (!formInputs.Email) {
      setAlert('Email');
      return;
    }
    if (!formInputs.Message) {
      setAlert('Message');
      return;
    }
    if (!phoneNumber) {
      setAlert('Phone number');
      return;
    }
    const html = generateHtml(
      subject,
      formInputs.Name,
      formInputs.Email,
      phoneNumber,
      formInputs.Message,
      formInputs.Make,
      formInputs.PriceL,
      formInputs.PriceH,
      formInputs.YearL,
      formInputs.YearH,
      formInputs.MileageL,
      formInputs.MileageH,
      formInputs.Mileage,
      formInputs.VIN
    );
    await setDoc(doc(db, 'mail', uid), {
      to: ['srautogroup1206@gmail.com'],
      message: {
        subject: subject,
        html: html,
      },
    })
      .then(() => {
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 5000);
      })
      .catch((error) => {
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 5000);
      });
  };

  useEffect(() => {
    window.scrollTo({ top: 300, behavior: 'smooth' });
  }, []);
  useEffect(() => {
    if (type) {
      switch (type) {
        case 'service':
          setSubject('Request service');
          handleSubjectChange('Request service');
          break;
        case 'wrapping':
          setSubject('Wrap car');
          handleSubjectChange('Wrap car');
          break;

        case 'SPA':
          setSubject('Auto SPA');
          handleSubjectChange('Auto SPA');
          break;
        case 'Sell':
          setSubject('Sell car');
          handleSubjectChange('Sell car');
          break;

        case 'Find':
          setSubject('Find your dream car');
          handleSubjectChange('Find your dream car');
          break;
        case 'Buy':
          setSubject('Buy car');
          handleSubjectChange('Buy car');

          if (location && location.state && location.state.detail) {
            const Car = location.state.detail;
            setFormInputs((values) => ({
              ...values,
              Message: `I am interested in ${Car.Make} ${Car.Model} ${Car.ModelYear}`,
            }));
            return;
          }
          break;
      }
    }
  }, [handleSubjectChange, location, type]);

  return (
    <React.Fragment>
      <section className="page-header contactus_page contactUs-header">
        <div className="container">
          <div className="page-header_wrap">
            <div>
              <h1 className="contactUs-title">Contact Us</h1>
            </div>
          </div>
        </div>
      </section>

      <section className="contact_us section-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h3>Get in touch using the form below</h3>
              {alert && (
                <Alert severity="warning">Please fill out {alert}!</Alert>
              )}
              {success && (
                <Alert severity="success">
                  Email sent, we will contact you soon!
                </Alert>
              )}
              {error && (
                <Alert severity="error">Something went wrong,try again!</Alert>
              )}
              <div className="contact_form gray-bg">
                <div className="form-group">
                  <label className="control-label">
                    Subject<span>*</span>
                  </label>
                  <Select
                    id="reqeust-reason-select"
                    className="form-control white_bg"
                    value={subject}
                    onChange={(event) =>
                      handleSubjectChange(event.target.value)
                    }
                  >
                    <MenuItem value={'Buy car'}>Buy car</MenuItem>
                    <MenuItem value={'Sell car'}>Sell car</MenuItem>
                    <MenuItem value={'Wrap car'}>Wrap car</MenuItem>
                    <MenuItem value={'Request service'}>
                      Request service
                    </MenuItem>
                    <MenuItem value={'Auto SPA'}>Auto SPA</MenuItem>
                    <MenuItem value={'Find your dream car'}>
                      Find your deam car
                    </MenuItem>
                  </Select>
                </div>
                <div className="form-group">
                  <label className="control-label">
                    Full Name <span>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control white_bg"
                    name="Name"
                    value={formInputs.Name}
                    onChange={handleFormInputChange}
                  />
                </div>
                <div className="form-group">
                  <label className="control-label">
                    Email Address <span>*</span>
                  </label>
                  <input
                    type="email"
                    className="form-control white_bg"
                    name="Email"
                    value={formInputs.Email}
                    onChange={handleFormInputChange}
                  />
                </div>
                <div className="form-group">
                  <label className="control-label">
                    Phone Number <span>*</span>
                  </label>

                  <IMaskInput
                    mask="(#00) 000-0000"
                    definitions={{
                      '#': /[1-9]/,
                    }}
                    onAccept={(value: any) => setPhoneNumber(value)}
                    value={phoneNumber}
                    overwrite
                  />
                </div>
                {subject === 'Sell car' && (
                  <React.Fragment>
                    <div className="form-group">
                      <label className="control-label">
                        Mileage <span>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control white_bg"
                        name="Mileage"
                        value={formInputs.Mileage}
                        onChange={handleFormInputChange}
                      />
                    </div>
                    <div className="form-group">
                      <label className="control-label">
                        VIN<span>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control white_bg"
                        name="VIN"
                        value={formInputs.VIN}
                        onChange={handleFormInputChange}
                      />
                    </div>
                  </React.Fragment>
                )}
                {subject === 'Find your dream car' && (
                  <React.Fragment>
                    <div className="form-group">
                      <label className="control-label">
                        Car Make <span>*</span>
                      </label>
                      <input
                        className="form-control white_bg"
                        name="Make"
                        value={formInputs.Make}
                        onChange={handleFormInputChange}
                      />
                    </div>
                    <div className="form-group">
                      <label className="control-label">
                        Price range: <span>*</span>
                      </label>
                      <div className="form-control">
                        <FormControl
                          sx={{ m: 1, width: '40%' }}
                          variant="standard"
                        >
                          <Input
                            name="PriceL"
                            value={formInputs.PriceL}
                            onChange={handleFormInputChange}
                            placeholder="50000"
                            startAdornment={
                              <InputAdornment position="start">
                                From: $
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                        <FormControl
                          sx={{ m: 1, width: '40%' }}
                          variant="standard"
                        >
                          <Input
                            name="PriceH"
                            value={formInputs.PriceH}
                            onChange={handleFormInputChange}
                            placeholder="100000"
                            startAdornment={
                              <InputAdornment position="start">
                                To: $
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="control-label">
                        Year range: <span>*</span>
                      </label>
                      <div className="form-control">
                        <FormControl
                          sx={{ m: 1, width: '40%' }}
                          variant="standard"
                        >
                          <Input
                            name="YearL"
                            value={formInputs.YearL}
                            onChange={handleFormInputChange}
                            placeholder="2017"
                            startAdornment={
                              <InputAdornment position="start">
                                From:
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                        <FormControl
                          sx={{ m: 1, width: '40%' }}
                          variant="standard"
                        >
                          <Input
                            name="YearH"
                            value={formInputs.YearH}
                            onChange={handleFormInputChange}
                            placeholder="2022"
                            startAdornment={
                              <InputAdornment position="start">
                                To:{' '}
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="control-label">
                        Mileage range: <span>*</span>
                      </label>
                      <div className="form-control">
                        <FormControl
                          sx={{ m: 1, width: '40%' }}
                          variant="standard"
                        >
                          <Input
                            name="MileageL"
                            value={formInputs.MileageL}
                            onChange={handleFormInputChange}
                            placeholder="10000"
                            startAdornment={
                              <InputAdornment position="start">
                                From:
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                        <FormControl
                          sx={{ m: 1, width: '40%' }}
                          variant="standard"
                        >
                          <Input
                            name="MileageH"
                            value={formInputs.MileageH}
                            onChange={handleFormInputChange}
                            placeholder="50000"
                            startAdornment={
                              <InputAdornment position="start">
                                To:{' '}
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      </div>
                    </div>
                  </React.Fragment>
                )}

                <div className="form-group">
                  <label className="control-label">
                    Message <span>*</span>
                  </label>
                  <textarea
                    className="form-control height-remove white_bg"
                    name="Message"
                    value={formInputs.Message}
                    onChange={handleFormInputChange}
                    placeholder={messageTips}
                    rows={4}
                  ></textarea>
                </div>
                <div className="form-group">
                  <button className="btn" type="submit" onClick={handleSubmit}>
                    Send Message{' '}
                    <span className="angle_arrow">
                      <i className="fa fa-angle-right" aria-hidden="true"></i>
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <h3>Contact Info</h3>
              <div className="contact_detail">
                <ul>
                  <li>
                    <div className="icon_wrap">
                      <i className="fa fa-map-marker" aria-hidden="true"></i>
                    </div>
                    <div className="contact_info_m">
                      <a
                        target="_blank"
                        href="http://maps.google.com/?q=SR Auto Group Massachusetts, Quincy, W Howard St,02169"
                        rel="noreferrer"
                      >
                        SR Auto Group Massachusetts, Quincy, W Howard St
                      </a>
                    </div>
                  </li>
                  <li>
                    <div className="icon_wrap">
                      <i className="fa fa-phone" aria-hidden="true"></i>
                    </div>
                    <div className="contact_info_m">
                      <a
                        target="_blank"
                        href="tel:617-481-8998"
                        rel="noreferrer"
                      >
                        +1 617-481-8998{' '}
                      </a>
                    </div>
                  </li>
                  <li>
                    <div className="icon_wrap">
                      <i className="fa fa-envelope-o" aria-hidden="true"></i>
                    </div>
                    <div className="contact_info_m">
                      <a
                        target="_blank"
                        href="mailto:srautogroup.ma@gmail.com"
                        rel="noreferrer"
                      >
                        srautogroup.ma@gmail.com
                      </a>
                    </div>
                  </li>
                </ul>
                <div className="map_wrap">
                  <iframe
                    src="https://maps.google.com/maps?q=SR%20autogroup&t=&z=13&ie=UTF8&iwloc=&output=embed"
                    width="100%"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default ContactUs;

import React from 'react';
import { Link } from 'react-router-dom';

import './navigation.css';
import xiaohongshu from '../../images/xiaohongshu.png';
const Navigation: React.FC = () => {
  let toggleButton;
  return (
    <React.Fragment>
      <header>
        <div className="default-header">
          <div className="container ">
            <div className="row">
              <div className="col-sm-6 col-md-5">
                <Link to="/">
                  <div className="logo"></div>
                </Link>
              </div>
              <div className="col-sm-6 col-md-7">
                <div className="header_info">
                  <div className="header_widgets">
                    <div className="circle_icon">
                      {' '}
                      <i className="fa fa-phone" aria-hidden="true"></i>{' '}
                    </div>
                    <p className="uppercase_text"> Call Us: </p>
                    <a href="tel:617-481-8998">+1 617-481-8998 </a>{' '}
                  </div>
                  <div className="header_widgets">
                    <div className="circle_icon">
                      {' '}
                      <i className="fa fa-envelope" aria-hidden="true"></i>{' '}
                    </div>
                    <p className="uppercase_text"> Mail us : </p>
                    <a href="mailto:srautogroup.ma@gmail.com">
                      srautogroup.ma@gmail.com
                    </a>{' '}
                  </div>

                  <div className="social-follow">
                    <ul>
                      <li>
                        <a
                          href="https://www.instagram.com/invites/contact/?i=q6rz9g819jcy&utm_content=md7xrz9"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i
                            className="fa fa-brands fa-instagram"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.xiaohongshu.com/user/profile/613a4a9b00000000020181a1?xhsshare=CopyLink&appuid=613a4a9b00000000020181a1&apptime=1673835965"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            className="navigation-xiaohongshu"
                            src={xiaohongshu}
                            alt="xiaohongshu logo"
                          ></img>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <nav id="navigation_bar" className="navbar navbar-expand-lg">
        <div className="container">
          <div className="row header-row desktop">
            <div className="navbar-header">
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                ref={(button) => {
                  toggleButton = button;
                }}
              >
                {' '}
                <i className="fa fa-bars"></i>{' '}
              </button>
            </div>

            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="nav navbar-nav">
                <li>
                  <Link onClick={() => toggleButton.click()} to="/">
                    Home
                  </Link>
                </li>
                <li>
                  <Link onClick={() => toggleButton.click()} to="/inventory">
                    Inventory
                  </Link>
                </li>
                <li>
                  <Link onClick={() => toggleButton.click()} to="/services">
                    Services
                  </Link>
                </li>
                <li>
                  <Link onClick={() => toggleButton.click()} to="/wrapping">
                    {' '}
                    Wrapping
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => toggleButton.click()}
                    to={{
                      pathname: '/contactUs',
                      search: `?subject=Sell`,
                    }}
                  >
                    {' '}
                    Sell your car
                  </Link>
                </li>
                <li>
                  <Link onClick={() => toggleButton.click()} to="/autoSPA">
                    AUTO SPA
                  </Link>
                </li>

                <li>
                  <a
                    target="_blank"
                    href="https://form.jotform.com/230306461640143"
                    rel="noreferrer"
                    onClick={() => toggleButton.click()}
                  >
                    REQUEST LOAN
                  </a>
                </li>
                <li>
                  <Link onClick={() => toggleButton.click()} to="/contactUs">
                    {' '}
                    Contact us
                  </Link>
                </li>
                <li>
                  <Link onClick={() => toggleButton.click()} to="/aboutUs">
                    {' '}
                    About us
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="row header-row mobile">
            <div className="col-10 col-md-8 right">
              <div className="navbar-header">
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  ref={(button) => {
                    toggleButton = button;
                  }}
                >
                  {' '}
                  <i className="fa fa-bars"></i>{' '}
                </button>
              </div>

              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="nav navbar-nav">
                  <li>
                    <Link onClick={() => toggleButton.click()} to="/">
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => toggleButton.click()} to="/inventory">
                      Inventory
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => toggleButton.click()} to="/services">
                      Services
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => toggleButton.click()} to="/wrapping">
                      {' '}
                      Wrapping
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => toggleButton.click()}
                      to={{
                        pathname: '/contactUs',
                        search: `?subject=Sell`,
                      }}
                    >
                      {' '}
                      Sell your car
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => toggleButton.click()} to="/autoSPA">
                      AUTO SPA
                    </Link>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://form.jotform.com/230306461640143"
                      rel="noreferrer"
                      onClick={() => toggleButton.click()}
                    >
                      REQUEST LOAN
                    </a>
                  </li>
                  <li>
                    <Link onClick={() => toggleButton.click()} to="/contactUs">
                      {' '}
                      Contact us
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => toggleButton.click()} to="/aboutUs">
                      {' '}
                      About us
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </React.Fragment>
  );
};

export default Navigation;

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: 'AIzaSyDXRn0btpqDuKnQG8rO4Pgim7eMLpKUMKg',
  authDomain: 'srautogroup-f2e05.firebaseapp.com',
  projectId: 'srautogroup-f2e05',
  storageBucket: 'srautogroup-f2e05.appspot.com',
  messagingSenderId: '379666248886',
  appId: '1:379666248886:web:4fb7910a8fcf757d78a217',
  measurementId: 'G-8P4N1RRNFF',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const db = getFirestore(app);
export const auth = getAuth(app);

import React, { useEffect, useMemo, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  Modal,
  Pagination,
  Paper,
  Select,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import './inventory.css';

import SearchIcon from '@mui/icons-material/Search';
import {
  query,
  orderBy,
  getDocs,
  collection,
  getCountFromServer,
  where,
  updateDoc,
  doc,
  deleteDoc,
} from 'firebase/firestore';
import { auth, db } from '../../firebase';
import { getStorage, ref, deleteObject, listAll } from 'firebase/storage';
import { NumericFormat } from 'react-number-format';
import { Link } from 'react-router-dom';
import SoldImage from '../../images/sold.png';
import PaidIcon from '@mui/icons-material/Paid';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import { Filter } from './filter';

export interface CarProps {
  Description?: string;
  Cover?: string;
  timestamp?: string;
  Image?: string;
  VIN?: string;
  Make?: string;
  Model?: string;
  ModelYear?: string;
  NumberOfOwner?: string;
  Exterior?: string;
  Interior?: string;
  PriceOrignial?: string;
  PriceDiscount?: string;
  EngineType?: string;
  EngineDescription?: string;
  Mileage?: string;
  TransmissionType?: string;
  FuelType?: string;
  SeatingCapacity?: string;
  Sold?: boolean;
  ANDROIDAUTO?: boolean;
  APPLECARPLAY?: boolean;
  BACKUPCAMERA?: boolean;
  ADAPTIVECRUISECONTROL?: boolean;
  BLUETOOTH?: boolean;
  HEATEDSEATS?: boolean;
  KEYLESSENTRY?: boolean;
  LANEDEPARTUREWARNING?: boolean;
  MEMORYSEATS?: boolean;
  NAVIGATIONSYSTEM?: boolean;
  PARKINGSENSORSASSIST?: boolean;
  POWERSEATS?: boolean;
  RAINSENSINGWIPERS?: boolean;
  SATELLITERADIOREADY?: boolean;
  SIDEIMPACTAIRBAGS?: boolean;
  SUNROOFMOONROOF?: boolean;
  XENONHEADLIGHTS?: boolean;
  BLINDSPOTMONITOR?: boolean;
}
interface CarListProps {
  Car: CarProps;
}

const CarList: React.FC<CarListProps> = ({ Car }) => {
  const [succeed, setSucceed] = useState(false);
  const [error, setError] = useState('');
  const [user, setUser] = useState('');
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const storage = getStorage();
  const [loading, setLoading] = useState(false);

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 250,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) setUser(user?.uid);
    });
  }, []);
  const handleSoldClick = async () => {
    const carRef = doc(db, 'cars', Car.Image);

    await updateDoc(carRef, {
      Sold: true,
    })
      .then(() => {
        setSucceed(true);
        setTimeout(() => {
          setSucceed(false);
        }, 5000);
      })
      .catch((e) => {
        setError(e);
        setTimeout(() => {
          setError('');
        }, 5000);
      });
  };

  const handleRestoreClick = async () => {
    const carRef = doc(db, 'cars', Car.Image);

    await updateDoc(carRef, {
      Sold: false,
    })
      .then(() => {
        setSucceed(true);
        setTimeout(() => {
          setSucceed(false);
        }, 5000);
      })
      .catch((e) => {
        setError(e);
        setTimeout(() => {
          setError('');
        }, 5000);
      });
  };
  const handleDeleteClick = async () => {
    setLoading(true);

    const storageRef = ref(storage, `images/${Car.Image}`);
    const carRef = doc(db, 'cars', Car.Image);

    await listAll(storageRef).then((listResults) => {
      const promises = listResults.items.map((item) => {
        return deleteObject(item);
      });
      Promise.all(promises)
        .then(async () => {
          await deleteDoc(carRef)
            .then(() => {
              window.location.reload();
            })
            .catch((e) => {
              setLoading(false);
              setError(e);
              setTimeout(() => {
                setError('');
              }, 5000);
            });
        })
        .catch((error) => {
          setLoading(false);
          setError(error);
          setTimeout(() => {
            setError('');
          }, 5000);
        });
    });
  };

  return (
    <div className="col-md-4 grid_listing">
      <div className="product-listing-m gray-bg">
        {succeed && <Alert severity="success">Succeed</Alert>}
        {error && <Alert severity="error"> {error} </Alert>}
        <div className="product-listing-img">
          <Link
            to={{
              pathname: '/carDetail',
              search: `?carId=${Car.Image}`,
            }}
            state={{ detail: Car }}
          >
            <div className="inventory-images">
              <img
                src={Car.Cover}
                className="img-fluid"
                alt="car-cover"
                style={{ height: '100%' }}
              />
              {Car.Sold && (
                <img
                  src={SoldImage}
                  className="inventory-image-top"
                  alt="sold"
                  style={{ width: '100px' }}
                />
              )}
            </div>
          </Link>
        </div>
        <div className="product-listing-content">
          <div className="inventory-carName">
            <h5>
              <Link
                to={{
                  pathname: '/carDetail',
                  search: `?carId=${Car.Image}`,
                }}
                state={{ detail: Car }}
              >
                {Car.Make} {Car.Model}
              </Link>
            </h5>
          </div>

          <div>
            <NumericFormat
              className="list-price"
              value={Car.PriceDiscount}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
            {user && !Car.Sold && (
              <Button
                variant="contained"
                color="error"
                className="iventory-image-button"
                onClick={handleSoldClick}
                size="small"
              >
                Sold
              </Button>
            )}
            {user && Car.Sold && (
              <Button
                variant="contained"
                color="error"
                className="iventory-image-button"
                onClick={handleRestoreClick}
                size="small"
              >
                Restore
              </Button>
            )}
            {user && (
              <Button
                variant="contained"
                className="iventory-image-button"
                onClick={handleOpen}
                size="small"
              >
                Delete
              </Button>
            )}
          </div>

          <ul className="features_list">
            <li>
              <i className="fa fa-tachometer" aria-hidden="true"></i>
              <NumericFormat
                value={Car.Mileage}
                displayType={'text'}
                thousandSeparator={true}
              />
            </li>
            <li>
              <i className="fa fa-calendar" aria-hidden="true"></i>
              {Car.ModelYear} model
            </li>
          </ul>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="inventory-modal">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure?
          </Typography>
          {loading && (
            <div className="inventory-loading">
              <CircularProgress color="inherit" size={30} />
            </div>
          )}

          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              color="error"
              onClick={handleDeleteClick}
              size="small"
            >
              Confirm
            </Button>
            <Button variant="outlined" onClick={handleClose} size="small">
              Cancel
            </Button>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
};

const Inventory: React.FC = () => {
  const PAGE_SIZE = 9;
  const [searchContent, setSearchContent] = useState('');
  const [sortOrder, setSortOrder] = useState('Newest');
  const [carList, setCarList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [carCount, setCarCount] = useState(0);
  const [page, setPage] = useState(1);
  const [pageAmount, setPageAmount] = useState(1);
  const [value, setValue] = useState(0);
  const [tab, setTab] = useState('inStock');

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    if (newValue === 0) {
      setTab('inStock');
      return;
    }
    setTab('sold');
  };

  const handleSearchClick = async () => {
    if (searchContent.length === 0) return;
    const dbRef = collection(db, 'cars');

    const make = query(
      dbRef,
      where('Sold', '==', tab === 'sold'),
      where('MakeSearch', '>=', searchContent.toUpperCase()),
      where('MakeSearch', '<=', searchContent.toUpperCase() + '~')
    );
    const model = query(
      dbRef,
      where('Sold', '==', tab === 'sold'),
      where('ModelSearch', '>=', searchContent.toUpperCase()),
      where('ModelSearch', '<=', searchContent.toUpperCase() + '~')
    );

    const subscribe = async () => {
      setLoading(true);
      const makeDocuments = await getDocs(make);
      const makeTempPosts = [];
      makeDocuments.forEach((document) => {
        makeTempPosts.push({
          ...document.data(),
        });
      });
      const modelDocuments = await getDocs(model);
      const modelTempPosts = [];
      modelDocuments.forEach((document) => {
        modelTempPosts.push({
          ...document.data(),
        });
      });
      const result = makeTempPosts.concat(modelTempPosts);
      setCarCount(result.length);
      if (sortOrder === 'Newest') {
        result.sort(function (a, b) {
          return b.timestamp - a.timestamp;
        });
      }
      if (sortOrder === 'HighToLow') {
        result.sort(function (a, b) {
          return b.PriceDiscount - a.PriceDiscount;
        });
      }

      if (sortOrder === 'LowToHigh') {
        result.sort(function (a, b) {
          return a.PriceDiscount - b.PriceDiscount;
        });
      }
      setCarList(result);
      setLoading(false);
    };
    subscribe();
  };

  const handleFilterApply = async (conditions) => {
    const dbRef = collection(db, 'cars');

    const AllCars = query(dbRef, where('Sold', '==', false));

    const subscribe = async () => {
      setLoading(true);

      const data = await getDocs(AllCars);
      let result = [];
      data.forEach((document) => {
        result.push({
          ...document.data(),
        });
      });
      if (conditions.make) {
        result = result.filter(
          (car) =>
            car.Make.toUpperCase().includes(conditions.make.toUpperCase()) ||
            conditions.make.toUpperCase().includes(car.Make.toUpperCase())
        );
      }
      if (conditions.model) {
        result = result.filter(
          (car) =>
            car.Model.toUpperCase().includes(conditions.model.toUpperCase()) ||
            conditions.model.toUpperCase().includes(car.Model.toUpperCase())
        );
      }
      if (conditions.maxPrice) {
        result = result.filter(
          (car) => parseInt(car.PriceDiscount) <= parseInt(conditions.maxPrice)
        );
      }

      if (conditions.maxMileage) {
        result = result.filter(
          (car) => parseInt(car.Mileage) <= parseInt(conditions.maxMileage)
        );
      }

      setCarCount(result.length);
      if (sortOrder === 'Newest') {
        result.sort(function (a, b) {
          return b.timestamp - a.timestamp;
        });
      }
      if (sortOrder === 'HighToLow') {
        result.sort(function (a, b) {
          return b.PriceDiscount - a.PriceDiscount;
        });
      }

      if (sortOrder === 'LowToHigh') {
        result.sort(function (a, b) {
          return a.PriceDiscount - b.PriceDiscount;
        });
      }
      setCarList(result);
      setLoading(false);
    };
    subscribe();
  };

  useEffect(() => {
    window.scrollTo({ top: 300, behavior: 'smooth' });
  }, [page]);

  const currentPageData = useMemo(() => {
    const firstPageIndex = (page - 1) * PAGE_SIZE;
    const lastPageIndex = firstPageIndex + PAGE_SIZE;
    return carList.slice(firstPageIndex, lastPageIndex);
  }, [carList, page]);

  const handleChange = async (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };
  const handleSortChange = (event) => {
    setPage(1);
    setSortOrder(event.target.value);
  };
  useEffect(() => {
    if (searchContent.length > 0) {
      handleSearchClick();
    }
  }, [sortOrder]);
  useEffect(() => {
    if (searchContent.length === 0) {
      setLoading(true);
      const coll = collection(db, 'cars');
      const query_ = query(coll, where('Sold', '==', tab === 'sold'));
      const getCarListCount = async () => {
        const snapshot = await getCountFromServer(query_);
        setCarCount(snapshot.data().count);
      };
      if (tab === 'sold') {
        const first = query(
          collection(db, 'cars'),
          where('Sold', '==', true),
          orderBy('timestamp', 'desc')
        );

        const subscribe = async () => {
          const documents = await getDocs(first);
          const tempPosts = [];

          documents.forEach((document) => {
            tempPosts.push({
              ...document.data(),
            });
          });
          setCarList(tempPosts);
          setLoading(false);
        };
        subscribe();
      } else {
        if (sortOrder === 'Newest') {
          const first = query(
            collection(db, 'cars'),
            where('Sold', '==', false),
            orderBy('timestamp', 'desc')
          );

          const subscribe = async () => {
            const documents = await getDocs(first);
            const tempPosts = [];
            documents.forEach((document) => {
              tempPosts.push({
                ...document.data(),
              });
            });
            setCarList(tempPosts);
            setLoading(false);
          };
          subscribe();
        }
        if (sortOrder === 'HighToLow') {
          const first = query(
            collection(db, 'cars'),
            where('Sold', '==', false),
            orderBy('PriceDiscount', 'desc')
          );

          const subscribe = async () => {
            const documents = await getDocs(first);
            const tempPosts = [];
            documents.forEach((document) => {
              tempPosts.push({
                ...document.data(),
              });
            });
            setCarList(tempPosts);
            setLoading(false);
          };
          subscribe();
        }
        if (sortOrder === 'LowToHigh') {
          const first = query(
            collection(db, 'cars'),
            where('Sold', '==', false),
            orderBy('PriceDiscount', 'asc')
          );

          const subscribe = async () => {
            const documents = await getDocs(first);
            const tempPosts = [];
            documents.forEach((document) => {
              tempPosts.push({
                ...document.data(),
              });
            });
            setCarList(tempPosts);
            setLoading(false);
          };
          subscribe();
        }
      }

      getCarListCount();
    }
  }, [searchContent.length, sortOrder, tab]);

  useEffect(() => {
    setPageAmount(Math.ceil(carCount / PAGE_SIZE));
  }, [carCount]);

  useEffect(() => {
    window.scrollTo({ top: 300, behavior: 'smooth' });
  }, []);

  return (
    <React.Fragment>
      <section className="page-header listing_page inventory-header">
        <div className="container">
          <div className="page-header_wrap">
            <div>
              <h1 className="inventory-header-text">Inventory</h1>
            </div>
          </div>
        </div>
      </section>

      <section className="listing-page inventory-content">
        <div className="container">
          <div className="row">
            <div className="col-md-9 col-md-push-3">
              <div className="mobile_search">
                <div className="sidebar_widget">
                  <div className="widget_heading">
                    <h5>
                      <i className="fa fa-filter" aria-hidden="true"></i> Search{' '}
                    </h5>
                    <div className="sidebar_filter">
                      <Paper
                        component="form"
                        sx={{
                          p: '2px 4px',
                          display: 'flex',
                          alignItems: 'center',
                          minWidth: 180,
                        }}
                      >
                        <InputBase
                          sx={{ ml: 1, flex: 1 }}
                          placeholder="Search Car"
                          value={searchContent}
                          onChange={(event) =>
                            setSearchContent(event.target.value)
                          }
                        />
                        <IconButton
                          type="button"
                          sx={{ p: '10px' }}
                          aria-label="search"
                          onClick={handleSearchClick}
                        >
                          <SearchIcon />
                        </IconButton>
                      </Paper>
                    </div>
                  </div>
                  <div className="sidebar_filter"></div>
                </div>
              </div>
              <div className="result-sorting-wrapper">
                <div className="sorting-count">
                  <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <Tabs
                        value={value}
                        onChange={handleTabChange}
                        aria-label="basic tabs example"
                      >
                        <Tab
                          icon={<DirectionsCarIcon />}
                          label="In stock"
                          iconPosition="start"
                        />
                        <Tab
                          icon={<PaidIcon />}
                          label="Sold"
                          iconPosition="start"
                        />
                      </Tabs>
                    </Box>
                  </Box>
                </div>
                {tab !== 'sold' && (
                  <div className="result-sorting-by">
                    <div className="inventory-sorting-container">
                      <Filter handleFilterApply={handleFilterApply} />
                      <FormControl sx={{ m: 1, minWidth: 180 }} size="small">
                        <InputLabel id="sort-select">Sort by</InputLabel>
                        <Select
                          id="sort-select"
                          value={sortOrder}
                          label="Sort by"
                          onChange={(event) => {
                            handleSortChange(event);
                          }}
                        >
                          <MenuItem value={'Newest'}>Newest Items</MenuItem>
                          <MenuItem value={'LowToHigh'}>
                            Price (low to high)
                          </MenuItem>
                          <MenuItem value={'HighToLow'}>
                            Price (high to low)
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                )}
              </div>

              <div className="row">
                {loading ? (
                  <div className="inventory-loading">
                    <CircularProgress color="inherit" size={100} />
                  </div>
                ) : carList.length === 0 ? (
                  <h1>No Cars</h1>
                ) : (
                  currentPageData.map((car) => <CarList Car={car} />)
                )}
              </div>

              <div className="inventory-pagination">
                {carList.length !== 0 && (
                  <Pagination
                    className="iventory-pagination-item"
                    count={pageAmount}
                    page={page}
                    onChange={handleChange}
                    variant="outlined"
                    shape="rounded"
                  />
                )}
              </div>
            </div>

            <aside className="col-md-3 col-md-pull-9">
              <div className="sidebar_widget sidebar_search_wrap">
                <div className="widget_heading">
                  <h5>
                    <i className="fa fa-filter" aria-hidden="true"></i> Search{' '}
                  </h5>
                </div>
                <div className="sidebar_filter">
                  <Paper
                    sx={{
                      p: '2px 4px',
                      display: 'flex',
                      alignItems: 'center',
                      minWidth: 180,
                    }}
                  >
                    <InputBase
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="Search Car"
                      onChange={(event) => setSearchContent(event.target.value)}
                    />
                    <IconButton
                      type="button"
                      sx={{ p: '10px' }}
                      aria-label="search"
                      onClick={handleSearchClick}
                    >
                      <SearchIcon />
                    </IconButton>
                  </Paper>
                </div>
              </div>
              <div className="sidebar_widget ">
                <div className="white-text div_zindex text-center">
                  <h3>Sell Your Car</h3>
                  <p>Request a quote and sell your car now!</p>
                  <Link
                    to={{
                      pathname: '/contactUs',
                      search: `?subject=Sell`,
                    }}
                    className="btn"
                  >
                    Request a Quote{' '}
                    <span className="angle_arrow">
                      <i className="fa fa-angle-right" aria-hidden="true"></i>
                    </span>
                  </Link>
                </div>
                <div className="dark-overlay"></div>
              </div>

              <div className="sidebar_widget ">
                <div className="white-text div_zindex text-center">
                  <h3>Find dream Car</h3>
                  <p>Telling us about your dream car, we could find for you </p>
                  <Link
                    to={{
                      pathname: '/contactUs',
                      search: `?subject=Find`,
                    }}
                    className="btn"
                  >
                    Telling us{' '}
                    <span className="angle_arrow">
                      <i className="fa fa-angle-right" aria-hidden="true"></i>
                    </span>
                  </Link>
                </div>
                <div className="dark-overlay"></div>
              </div>
            </aside>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};
export default Inventory;

export const generateHtml = (
  subject: string,
  name: string,
  email: string,
  phone: string,
  message: string,
  make: string,
  priceL: string,
  priceH: string,
  yearL: string,
  yearH: string,
  mileageL: string,
  mileageH: string,
  mileage: string,
  VIN: string
) => {
  if (subject === 'Sell car') {
    return `<!DOCTYPE html>
  <html>
  <style>
  table, th, td {
    border:1px solid black;
  }
  </style>
  <body>
  <h1>Request type: ${subject}</h1>
  <table style="width:100%">
    <tr>
      <td>Request subject</td>
      <td>${subject}</td>
    </tr>
   <tr>
      <td>Customer name</td>
      <td>${name}</td>
  
    </tr>
    <tr>
      <td>Email</td>
      <td>${email}</td>
    </tr>
     <tr>
      <td>Phone</td>
      <td>${phone}</td>
    </tr>

      <tr>
      <td>Mileage</td>
      <td>${mileage}</td>
    </tr>
    <tr>
    <td>VIN</td>
    <td>${VIN}</td>
  </tr>
  </table>
  <h2>Message:</h2>
  <p>${message}</p>
  </body>
  </html>`;
  }
  if (subject === 'Find your dream car') {
    return `<!DOCTYPE html>
    <html>
    <style>
    table, th, td {
      border:1px solid black;
    }
    </style>
    <body>
    <h1>Request type: ${subject}</h1>
    <table style="width:100%">
      <tr>
        <td>Request subject</td>
        <td>${subject}</td>
      </tr>
     <tr>
        <td>Customer name</td>
        <td>${name}</td>
    
      </tr>
      <tr>
        <td>Email</td>
        <td>${email}</td>
      </tr>
       <tr>
        <td>Phone</td>
        <td>${phone}</td>
      </tr>
     
      
        <tr>
        <td>Make</td>
        <td>${make}</td>
      </tr>
        <tr>
          <td>Price range</td>
          <td>${priceL} to ${priceH}</td>
          </tr>
          <tr>
          <td>Year range</td>
          <td>${yearL} to ${yearH}</td>
          </tr>
          <tr>
          <td>Mileage range</td>
          <td>${mileageL} to ${mileageH}</td>
      </tr>
      
     
    </table>
    <h2>Message:</h2>
    <p>${message}</p>
    </body>
    </html>`;
  }
  return `<!DOCTYPE html>
  <html>
  <style>
  table, th, td {
    border:1px solid black;
  }
  </style>
  <body>
  <h1>Request type: ${subject}</h1>
  <table style="width:100%">
    <tr>
      <td>Request subject</td>
      <td>${subject}</td>
    </tr>
   <tr>
      <td>Customer name</td>
      <td>${name}</td>
  
    </tr>
    <tr>
      <td>Email</td>
      <td>${email}</td>
    </tr>
     <tr>
      <td>Phone</td>
      <td>${phone}</td>
    </tr>
   
  </table>
  <h2>Message:</h2>
  <p>${message}</p>
  </body>
  </html>`;
};

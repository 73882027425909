export const ParkingSensors: React.FC = () => {
  return (
    <svg
      className="di-svg"
      fill="#000000"
      height="48"
      viewBox="0 0 48 48"
      width="48"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="presentation"
      tabIndex={-1}
    >
      <path
        d="M33.929,31.36c3.344-3.299,6.55-6.74,9.659-10.273c1.043-1.186,1.084-2.622,0.352-3.712
            c-1.983-2.948-4.51-5.353-7.395-7.397c-1.071-0.758-2.527-0.695-3.713,0.352c-6.188,5.463-12.075,11.311-17.606,17.612
            c-1.042,1.185-0.934,2.517-0.351,3.711c1.519,3.114,3.733,5.757,7.396,7.398c1.214,0.546,2.513,0.676,3.711-0.351
            c1.74-1.498,3.443-3.035,5.112-4.604L33.929,31.36z M28.451,32.914c-3.066-0.455-7.092-4.482-7.545-7.548
            c1.317-0.431,2.634-0.859,3.954-1.286c1.252,1.822,3.056,3.627,4.878,4.878C29.309,30.277,28.88,31.595,28.451,32.914L28.451,32.914
            z M40.284,20.765c-0.856-0.008-1.709-0.017-2.562-0.027c-1.353-1.627-3.1-3.376-4.729-4.729c-0.01-0.854-0.02-1.708-0.027-2.564
            C35.306,14.518,39.211,18.425,40.284,20.765L40.284,20.765z M22.503,23.216c2.462-2.978,5.437-5.766,8.584-8.484
            c0.146,0.693,0.289,1.389,0.437,2.084c-1.941,1.942-3.884,3.885-5.822,5.824C24.637,22.832,23.568,23.026,22.503,23.216
            L22.503,23.216z M30.623,31.337c0.192-1.063,0.385-2.132,0.576-3.198c1.938-1.939,3.882-3.883,5.822-5.823
            c0.695,0.146,1.391,0.29,2.085,0.438C36.388,25.899,33.6,28.876,30.623,31.337z"
        tabIndex={-1}
      ></path>
      <g tabIndex={-1}>
        <path
          fill="none"
          d="M6.474,12.512c0.386-0.594,0.775-1.186,1.161-1.777c-1.369-0.101-2.725-0.17-4.073-0.222v1.999
                C4.533,12.512,5.503,12.512,6.474,12.512z"
          tabIndex={-1}
        ></path>
        <path
          fill="none"
          d="M7.635,22.046c-0.386-0.595-0.775-1.187-1.161-1.779c-0.971,0-1.941,0-2.912,0v1.998
                C4.91,22.215,6.266,22.146,7.635,22.046z"
          tabIndex={-1}
        ></path>
        <path
          fill="none"
          d="M8.079,12.979c0.194,2.102,0.194,4.567,0,6.668c0.595,0.609,1.19,1.219,1.788,1.828
                c0.894-2.407,0.894-7.916,0-10.322C9.269,11.761,8.674,12.37,8.079,12.979z"
          tabIndex={-1}
        ></path>
        <path
          d="M14.835,11.231c-0.221-1.29-1.292-2.271-2.866-2.369c-2.794-0.175-5.598-0.271-8.407-0.33v1.98
                c1.348,0.052,2.704,0.121,4.073,0.222c-0.386,0.592-0.775,1.184-1.161,1.777c-0.971,0-1.941,0-2.912,0v7.755
                c0.971,0,1.94,0,2.912,0c0.386,0.593,0.775,1.185,1.161,1.779c-1.369,0.1-2.725,0.169-4.073,0.219v2.139
                c2.803-0.07,5.605-0.191,8.407-0.371c1.572-0.102,2.614-1.084,2.866-2.37C15.514,18.185,15.429,14.707,14.835,11.231z
                 M9.866,21.475c-0.597-0.609-1.192-1.219-1.788-1.828c0.194-2.101,0.194-4.566,0-6.668c0.595-0.608,1.19-1.218,1.788-1.826
                C10.76,13.559,10.76,19.067,9.866,21.475z"
          tabIndex={-1}
        ></path>
      </g>
    </svg>
  );
};
